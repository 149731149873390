import type { ActivitySubgroupForSynchronizationDto, ContractForSynchronizationDto, LocationForSynchronizationDto, OperatorForSynchronizationDto, ProcessForSynchronizationDto } from './dto/models';
import { RestService, Rest } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SynchronizationService {
  apiName = 'Default';
  

  getAllActivitySubgroupsForSynchronization = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, ActivitySubgroupForSynchronizationDto[]>({
      method: 'GET',
      url: '/api/app/synchronization/activity-subgroups-for-synchronization',
    },
    { apiName: this.apiName,...config });
  

  getAllContractsForSynchronization = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, ContractForSynchronizationDto[]>({
      method: 'GET',
      url: '/api/app/synchronization/contracts-for-synchronization',
    },
    { apiName: this.apiName,...config });
  

  getAllLocationsForSynchronization = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, LocationForSynchronizationDto[]>({
      method: 'GET',
      url: '/api/app/synchronization/locations-for-synchronization',
    },
    { apiName: this.apiName,...config });
  

  getAllOperatorsForSynchronization = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, OperatorForSynchronizationDto[]>({
      method: 'GET',
      url: '/api/app/synchronization/operators-for-synchronization',
    },
    { apiName: this.apiName,...config });
  

  getAllProcessesForSynchronization = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, ProcessForSynchronizationDto[]>({
      method: 'GET',
      url: '/api/app/synchronization/processes-for-synchronization',
    },
    { apiName: this.apiName,...config });

  constructor(private restService: RestService) {}
}
