import { PermissionService, ReplaceableComponentsService } from '@abp/ng.core';
import { UserMenuService } from '@abp/ng.theme.shared';
import { AfterViewInit, Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { eThemeLeptonXComponents, eUserMenuItems } from '@volosoft/abp.ng.theme.lepton-x';
import { Subscription } from 'rxjs';
import { AppComponentBase } from './shared/app-component-base';
import { AppConsts, LocalStorageConsts } from './shared/app-consts';
import { AppPermissions } from './shared/app-permissions';
import { CustomBreadcrumb } from './shared/components/custom-breadcrumb/custom-breadcrumb.component';
import { UsersRepository } from './shared/local-db/repositories/users-repository';
import { ActivitySubgroupsSyncService } from './shared/local-db/synchronization/activity-subgroups-sync-service';
import { ContractsSyncService } from './shared/local-db/synchronization/contracts-sync-service';
import { LocationsSyncService } from './shared/local-db/synchronization/locations-sync-service';
import { OperatorsSyncService } from './shared/local-db/synchronization/operators-sync-service';
import { ProcessesSyncService } from './shared/local-db/synchronization/processes-sync-service';
import { HealthCheckService } from './shared/services/HealthCheckService';

@Component({
  selector: 'app-root',
  template: `
    <abp-loader-bar></abp-loader-bar>
    <abp-dynamic-layout></abp-dynamic-layout>
  `,
})
export class AppComponent extends AppComponentBase implements AfterViewInit, OnInit, OnDestroy {
  healthCheckSubscription: Subscription;

  mobileLastToggledNavItem: Element;

  constructor(
    injector: Injector,
    private readonly _usersRepository: UsersRepository,
    private readonly _healthCheckService: HealthCheckService,
    private readonly _replaceableComponents: ReplaceableComponentsService,
    private readonly _permissionService: PermissionService,
    private readonly _userMenu: UserMenuService,
  ) {
    super(injector);
    if (this.hasMainPermission()) {
      this.injectSynchronizationServices(injector);
    }
    this.healthCheckSubscription = this._healthCheckService.heartBeat$.subscribe();
    this._healthCheckService.setBackendHeartbeatTimeout(LocalStorageConsts.HealthCheckInterval);

    this._titleService.setTitle(AppConsts.DefaultPageTitle);
  }

  ngOnDestroy(): void {
    this.healthCheckSubscription.unsubscribe();
  }

  async ngAfterViewInit(): Promise<void> {
    if (this.currentUser) {
      this.replaceFooterUsernameWithInitials();
    }

    this.customizeNavItemsBehaviour();

    if (this.hasMainPermission()) {
      try {
        await this._usersRepository.saveCurrentUserIfNeeded();
      } catch (e) {
        this._log.error(e);
        this._confirmation.error(
          this.l('::ErrorGettingExternalUserData'),
          this.l('::Error'),
          this.messagePopUpOptions,
        );
      }
    }
  }

  ngOnInit(): void {
    this._replaceableComponents.add({
      component: CustomBreadcrumb,
      key: eThemeLeptonXComponents.Breadcrumb,
    });
    this._replaceableComponents.add({
      component: null,
      key: eThemeLeptonXComponents.Footer,
    });

    this.removeUserMenuItems();
  }

  private injectSynchronizationServices(injector: Injector) {
    injector.get(ContractsSyncService);
    injector.get(ProcessesSyncService);
    injector.get(LocationsSyncService);
    injector.get(OperatorsSyncService);
    injector.get(ActivitySubgroupsSyncService);
  }

  private hasMainPermission(): boolean {
    return this._permissionService.getGrantedPolicy(AppPermissions.HourActivityReport);
  }

  private replaceFooterUsernameWithInitials() {
    let initials: string;
    if (this.currentUser.name && this.currentUser.surName) {
      initials = (this.currentUser.name[0] + this.currentUser.surName[0]).toUpperCase();
    } else {
      initials = this.currentUser.userName[0].toUpperCase();
    }

    const footerItems = document.querySelectorAll('.lpx-mobile-nav-tab .mobile-item-text');
    const footerUsername = footerItems[footerItems.length - 1];
    if (footerUsername) {
      footerUsername.textContent = initials;
    }
  }

  private removeUserMenuItems(): void {
    this._userMenu.removeItem(eUserMenuItems.MyAccount);
    this._userMenu.removeItem(eUserMenuItems.LinkedAccounts);
    this._userMenu.removeItem(eUserMenuItems.SecurityLogs);
    this._userMenu.removeItem(eUserMenuItems.AuthorityDelegation);
  }

  private customizeNavItemsBehaviour(): void {
    const rightNavItems = document.querySelectorAll(
      '.lpx-mobile-nav-tab-right .lpx-mobile-nav-item, .lpx-mobile-hamburger',
    );
    rightNavItems.forEach(navItem => {
      navItem.addEventListener('click', () => {
        if (this.mobileLastToggledNavItem === navItem) {
          this.mobileLastToggledNavItem = undefined;
        } else {
          this.mobileLastToggledNavItem = navItem;
        }
      });
    });

    const leftNavItems = document.querySelectorAll('.lpx-mobile-nav-tab-left .lpx-mobile-nav-item');
    leftNavItems.forEach(navItem => {
      navItem.addEventListener('click', () => {
        this.mobileLastToggledNavItem.dispatchEvent(new Event('click', { bubbles: true }));
      });
    });
  }
}
