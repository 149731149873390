import { Rest } from '@abp/ng.core';
import { Injector } from '@angular/core';
import { ServiceBase } from '../../services/service-base';
import { LocalDb } from '../local-db';

export abstract class LocalRepositoryBase extends ServiceBase {
  protected readonly _db: LocalDb;
  protected readonly remoteServicesConfig: Rest.Config = { skipHandleError: true };

  constructor(injector: Injector) {
    super(injector);
    this._db = injector.get(LocalDb);
  }

  isEmptyOrUndefined(value: any){
    return value == undefined || value == null || value == '' || value.toString().trim().length === 0;
  }
}
