import { PageModule } from '@abp/ng.components/page';
import { CoreModule } from '@abp/ng.core';
import { ThemeSharedModule } from '@abp/ng.theme.shared';
import { NgModule } from '@angular/core';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxValidateCoreModule } from '@ngx-validate/core';
import { CommercialUiModule } from '@volo/abp.commercial.ng.ui';
import { CustomBreadcrumb } from './components/custom-breadcrumb/custom-breadcrumb.component';
import { NetworkStatusNotificationComponent } from './components/network-status-notification/network-status-notification.component';
import { LocationService } from './services/location-service';

@NgModule({
  declarations: [NetworkStatusNotificationComponent, CustomBreadcrumb],
  imports: [
    CoreModule,
    ThemeSharedModule,
    CommercialUiModule,
    NgbDropdownModule,
    NgxValidateCoreModule,
    PageModule,
  ],
  exports: [
    CoreModule,
    ThemeSharedModule,
    CommercialUiModule,
    NgbDropdownModule,
    NgxValidateCoreModule,
    NetworkStatusNotificationComponent,
  ],
  providers: [LocationService],
})
export class SharedModule {}
