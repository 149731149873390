export class SynchronizationStatus {
  entity: SynchronizationEntity;
  lastSynchronizationDate: Date;
  lastSynchronizationAttemptDate: Date;
  synchronizationFrequencyInMilliseconds: number;
  lastSynchronizationErrorMessage: string;
  state: SynchronizationState;
  nextSynchronization: Date;
  isVisible: boolean;

  constructor(
    entity: SynchronizationEntity,
    synchronizationFrequencyInMilliseconds: number,
    isVisible: boolean,
  ) {
    this.entity = entity;
    this.synchronizationFrequencyInMilliseconds = synchronizationFrequencyInMilliseconds;
    this.isVisible = isVisible;
    this.state = SynchronizationState.Pending;
  }
}

export enum SynchronizationEntity {
  Contracts = 1,
  Processes = 2,
  Locations = 3,
  Operators = 4,
  ActivitySubgroups = 5,
  HourActivityReportPending = 6,
  Users = 7,
}

export enum SynchronizationState {
  Ok = 1,
  Error = 2,
  Pending = 3,
}
