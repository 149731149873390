import { Injectable, Injector } from "@angular/core";
import { SynchronizationServiceBase } from "./synchronization-service-base";
import { SynchronizationEntity } from "../entities/synchronization-status";
import { ProcessesRepository } from "../repositories/processes-repository";

@Injectable()
export class ProcessesSyncService extends SynchronizationServiceBase {
    constructor(
        injector: Injector,
        private readonly _processesRepository: ProcessesRepository) {
        super(injector);
    }

    protected entityToSync = SynchronizationEntity.Processes;

    async synchronize(): Promise<void> {
        const remoteProcesses = await this._processesRepository.fetchAll();
        await this._processesRepository.saveMany(remoteProcesses);
    }
}