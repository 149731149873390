import { Injectable } from '@angular/core';
import { MonitoringService } from './monitoring.service';

@Injectable({
    providedIn: 'root'
})
export class LoggingService {

    constructor(private _monitoringService: MonitoringService) { }

    error(error: any): void {
        this._monitoringService.logException(error);
        this.writeToLogFile(error);
    }

    private writeToLogFile(msg: any): void {
        let errorMsg = undefined
        if (msg.hasOwnProperty('stack')) {
            errorMsg = msg.stack;
        } else {
            let stringified = JSON.stringify(msg);
            if (stringified !== '{}') {
                errorMsg = stringified;
            } else {
                errorMsg = msg
            }
        }

        this._monitoringService.logException(errorMsg);
    }
}