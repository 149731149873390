export class Process {
    id: number;
    name: string;
    activitySubgroups: number[];

    constructor(id: number, name: string, activitySubgroups: number[]) {
        this.id = id;
        this.name = name;
        this.activitySubgroups = activitySubgroups;
    }
}