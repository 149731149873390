import type { HourActivityReportFrontendDto, UpdateOrCreateHourActivityReportOutputDto } from './models';
import { RestService, Rest } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class HourActivityReportService {
  apiName = 'Default';
  

  updateOrCreateHourActivityReportByHourActivityReportFrontendDto = (hourActivityReportFrontendDto: HourActivityReportFrontendDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, UpdateOrCreateHourActivityReportOutputDto>({
      method: 'PUT',
      url: '/api/app/hour-activity-report/or-create-hour-activity-report',
      body: hourActivityReportFrontendDto,
    },
    { apiName: this.apiName,...config });

  constructor(private restService: RestService) {}
}
