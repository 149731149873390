import { EventEmitter, Injectable, Injector, Output } from '@angular/core';
import {
  HourActivityReportProgressState as RemoteProgress,
  HourActivityReportState as RemoteState,
} from '@proxy/enums';
import {
  DeviceType,
  HourActivityReportActivityFrontendDto,
  HourActivityReportFrontendDto,
  HourActivityReportOperatorFrontendDto,
  HourActivityReportService,
  UpdateOrCreateHourActivityReportOutputDto,
} from '@proxy/hour-activity-reports';
import { firstValueFrom } from 'rxjs';
import { HourActivityReport } from '../entities/hour-activity-report';
import { HourActivityReportActivity } from '../entities/hour-activity-report-activity';
import {
  HourActivityReportOperator,
  OperatorMode,
} from '../entities/hour-activity-report-operator';
import { HourActivityReportProgressState } from '../entities/hour-activity-report-progress-state';
import { HourActivityReportState } from '../entities/hour-activity-report-state';
import { HourActivityReportSyncState } from '../entities/hour-activity-report-sync-state';
import { Operator } from '../entities/operator';
import { SynchronizationEntity, SynchronizationState } from '../entities/synchronization-status';
import { ActivitiesRepository } from '../repositories/activities-repository';
import { HourActivityReportRepository } from '../repositories/hour-activity-report-repository';
import { HourActivityReportSyncStateRepository } from '../repositories/hour-activity-report-sync-state-repository';
import { OperatorsRepository } from '../repositories/operators-repository';
import { SynchronizationServiceBase } from './synchronization-service-base';
import { SynchronizationFrequencies } from './synchronization-frequencies';
import { LoggingService } from '../../services/logging-service';

@Injectable()
export class ReportSenderService extends SynchronizationServiceBase {
  @Output() onSynchronized: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    injector: Injector,
    private _hourActivityReportsSyncRepository: HourActivityReportSyncStateRepository,
    private _hourActivityReportsRepository: HourActivityReportRepository,
    private _hourActivityReportProxy: HourActivityReportService,
    private _operatorsRepository: OperatorsRepository,
    private _activitiesRepository: ActivitiesRepository,
    readonly _log: LoggingService,
  ) {
    super(injector);
  }

  protected entityToSync = SynchronizationEntity.HourActivityReportPending;

  async synchronize(): Promise<void> {
    const hourActivityReportsPending = await this._hourActivityReportsSyncRepository.getAllError();

    for (const hourActivityReportPending of hourActivityReportsPending) {
      await this.synchronizeHourActivityReport(hourActivityReportPending);
    }
  }

  protected async scheduleInitialSync() {
    if (
      this.entityToSync !== undefined &&
      SynchronizationFrequencies.frequencies[this.entityToSync] !== undefined
    ) {
      this.configureFrequencies();

      const lastSync = await this.getFirstFailureDate();
      const timeout = this.getNextSyncTime(lastSync, this.syncInterval);
      await this.sleep(timeout);
      this.synchronizeAndReschedule();
    }
  }

  private async getFirstFailureDate(): Promise<Date | undefined> {
    const erroredReports = await this._hourActivityReportsSyncRepository.getAllError();
    if (erroredReports !== null && erroredReports !== undefined && erroredReports.length > 0) {
      return erroredReports.reduce(
        (x, y) => (x < y.lastSync ? x : y.lastSync),
        erroredReports[0].lastSync,
      );
    } else {
      return undefined;
    }
  }

  public async synchronizeHourActivityReport(
    hourActivityReportSync: HourActivityReportSyncState,
  ): Promise<UpdateOrCreateHourActivityReportOutputDto> {
    hourActivityReportSync.synchronizationState = SynchronizationState.Pending;
    await this._hourActivityReportsSyncRepository.update(hourActivityReportSync);
    let response = undefined;
    const hourActivityReport = await this._hourActivityReportsRepository.getById(
      hourActivityReportSync.hourActivityReportId,
    );
    const hourActivityReportInput = await this.createHourActivityReportToSynchronize(
      hourActivityReport,
      hourActivityReportSync,
    );
    try {
      response = await firstValueFrom(
        this._hourActivityReportProxy.updateOrCreateHourActivityReportByHourActivityReportFrontendDto(
          hourActivityReportInput,
          { skipHandleError: true },
        ),
      );
    } catch (error) {
      this._log.error(error);
    } finally {
      await this.processSynchronizationResponse(
        hourActivityReportSync,
        hourActivityReport,
        response,
      );
      return response;
    }
  }

  private async processSynchronizationResponse(
    hourActivityReportSync: HourActivityReportSyncState,
    hourActivityReport: HourActivityReport,
    response: UpdateOrCreateHourActivityReportOutputDto,
  ) {
    hourActivityReportSync.lastSync = new Date();
    hourActivityReportSync.numberOfTries += 1;
    if (response !== undefined && response.success) {
      hourActivityReportSync.synchronizationState = SynchronizationState.Ok;

      await this._hourActivityReportsRepository.setHourActivityReportRemoteId(
        hourActivityReport.id,
        response.reportId,
      );
      hourActivityReportSync.message = undefined;
      if (response.hasStateInformation) {
        hourActivityReport.state = remoteStateToLocalState(response.reportState);
      } else {
        if (hourActivityReport.state === HourActivityReportState.InProgress) {
          hourActivityReport.state = HourActivityReportState.PartiallyIntegrated;
        } else {
          hourActivityReport.state = HourActivityReportState.Integrated;
        }
      }
      await this._hourActivityReportsRepository.update(hourActivityReport);
    } else {
      hourActivityReportSync.synchronizationState = SynchronizationState.Error;
      if (response !== undefined) {
        hourActivityReportSync.message = this.l('::' + response.message);
      } else {
        hourActivityReportSync.message = this.l('::LocalDbSyncAbortedNoConnection');
      }
    }
    await this._hourActivityReportsSyncRepository.update(hourActivityReportSync);
    this.onSynchronized.emit();
  }

  private async createHourActivityReportToSynchronize(
    report: HourActivityReport,
    hourActivityReportSync: HourActivityReportSyncState,
  ): Promise<HourActivityReportFrontendDto> {
    const reportInput = new HourActivityReportDto(report);
    reportInput.deviceType = this.isMobile() ? DeviceType.Mobile : DeviceType.Desktop;

    let reportOperators = await this._operatorsRepository.getAllOperatorsByHourActivityReportId(
      report.id,
    );
    for (let reportOperator of reportOperators) {
      let operator = await this._operatorsRepository.getOperatorById(reportOperator.operatorId);
      const operatorDto = new HourActivityReportOperatorDto(reportOperator, operator);
      reportInput.operators.push(operatorDto);
    }

    if (!hourActivityReportSync.isPartial) {
      let activities = await this._activitiesRepository.getAllByHourActivityReportId(report.id);
      for (let activity of activities) {
        const activityDto = new HourActivityReportActivityDto(activity);
        reportInput.activities.push(activityDto);
      }
    }

    return reportInput;
  }
}

export class HourActivityReportDto implements HourActivityReportFrontendDto {
  id?: string;
  startDate?: Date;
  state: RemoteState;
  progressState: RemoteProgress;
  traveledDistance: number;
  observations?: string;
  processExternalId: number;
  locationExternalId: number;
  endLocationExternalId: number;
  contractExternalId: number;
  vendorId: number;
  userExternalId?: string;
  operators: HourActivityReportOperatorFrontendDto[];
  activities: HourActivityReportActivityFrontendDto[];
  number: string;
  deviceType: DeviceType;
  regionId: number;

  constructor(report: HourActivityReport) {
    this.id = report.remoteId;
    this.startDate = report.startDate;
    this.state = this.mapReportStateToBackendReportState(report.state);
    this.progressState = this.mapReportProgressStateToBackendReportProgressState(
      report.progressState,
    );
    this.traveledDistance = report.traveledDistance;
    this.observations = report.observations;
    this.processExternalId = report.process;
    this.locationExternalId = report.location;
    this.endLocationExternalId = report.endLocation;
    this.contractExternalId = report.contract;
    this.vendorId = report.vendorId;
    this.userExternalId = report.userExternalId;
    this.operators = [];
    this.activities = [];
    this.number = report.number;
    this.regionId = report.region;
  }

  private mapReportStateToBackendReportState(reportState: HourActivityReportState): number {
    switch (reportState) {
      case HourActivityReportState.Integrated: {
        return RemoteState.Integrated;
      }
      case HourActivityReportState.PartiallyIntegrated: {
        return RemoteState.PartiallyIntegrated;
      }
      case HourActivityReportState.Finished: {
        return RemoteState.Finished;
      }
      case HourActivityReportState.InProgress: {
        return RemoteState.InProgress;
      }
    }
  }

  private mapReportProgressStateToBackendReportProgressState(
    reportState: HourActivityReportProgressState,
  ): number {
    switch (reportState) {
      case HourActivityReportProgressState.ProcessSelected: {
        return RemoteProgress.ProcessSelected;
      }
      case HourActivityReportProgressState.PropertySelected: {
        return RemoteProgress.PropertySelected;
      }
      case HourActivityReportProgressState.ContractSelected: {
        return RemoteProgress.ContractSelected;
      }
      case HourActivityReportProgressState.OperatorsLoaded: {
        return RemoteProgress.OperatorsLoaded;
      }
      case HourActivityReportProgressState.StartDateSelected: {
        return RemoteProgress.StartDateSelected;
      }
      case HourActivityReportProgressState.Confirmed: {
        return RemoteProgress.Confirmed;
      }
      case HourActivityReportProgressState.Finishing: {
        return RemoteProgress.Finishing;
      }
      case HourActivityReportProgressState.Finished: {
        return RemoteProgress.Finished;
      }
    }
  }
}

export class HourActivityReportOperatorDto implements HourActivityReportOperatorFrontendDto {
  id?: number;
  operatorExternalId: number;
  operatorCode?: string;
  source: number;
  startLatitude: number;
  startLongitude: number;
  endLatitude: number;
  endLongitude: number;
  isManuallyConfirmed: boolean;
  qrCode?: string;

  constructor(reportOperator: HourActivityReportOperator, operator: Operator) {
    let isManual: boolean;
    if (reportOperator.confirmationMode !== undefined) {
      isManual = reportOperator.confirmationMode == OperatorMode.Manual;
    } else {
      isManual = reportOperator.mode == OperatorMode.Manual;
    }

    this.isManuallyConfirmed = isManual;
    this.id = reportOperator.id;
    this.operatorExternalId = reportOperator.operatorId;
    this.operatorCode = operator.code;
    this.source = reportOperator.confirmationMode;
    this.startLatitude = reportOperator.latitude;
    this.startLongitude = reportOperator.longitude;
    this.endLatitude = reportOperator.endLatitude;
    this.endLongitude = reportOperator.endLongitude;
    this.qrCode = !isManual ? operator.qrCode : '';
  }
}

export class HourActivityReportActivityDto implements HourActivityReportActivityFrontendDto {
  id?: number;
  startTime?: Date;
  endTime?: Date;
  activitySubGroupExternalId: number;
  locationId: number;

  constructor(activity: HourActivityReportActivity) {
    this.id = activity.id;
    this.activitySubGroupExternalId = activity.activitySubgroupId;
    this.locationId = activity.location;
    const today = new Date();
    this.startTime = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
      activity.startTime.hour,
      activity.startTime.minute,
      activity.startTime.second,
    );
    this.endTime = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
      activity.endTime.hour,
      activity.endTime.minute,
      activity.endTime.second,
    );
  }
}

function remoteStateToLocalState(reportState: RemoteState): HourActivityReportState {
  switch (reportState) {
    case RemoteState.Finished:
      return HourActivityReportState.Finished;
    case RemoteState.InProgress:
      return HourActivityReportState.InProgress;
    case RemoteState.Integrated:
      return HourActivityReportState.Integrated;
    case RemoteState.PartiallyIntegrated:
      return HourActivityReportState.PartiallyIntegrated;
  }
}
