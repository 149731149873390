export class Contract {
    id: number;
    vendorId: number;
    processes: number[];
    number: string;
    startDate: Date;
    dueDate: Date;

    constructor(id: number, vendorId: number, processes: number[], number: string, startDate: Date, dueDate: Date) {
        this.id = id;
        this.vendorId = vendorId;
        this.processes = processes;
        this.number = number;
        this.startDate = startDate;
        this.dueDate = dueDate;
    }
}