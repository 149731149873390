import { Confirmation, ConfirmationService } from "@abp/ng.theme.shared";
import { Injectable } from "@angular/core";
import { SwUpdate } from "@angular/service-worker";


@Injectable()
export class AppPwaVersionService {

    private readonly NewVersionDetected_Event = 'VERSION_DETECTED';

    public messagePopUpOptions: Partial<Confirmation.Options> = {
        hideCancelBtn: true,
        hideYesBtn: true,
        dismissible: true,
    };

    constructor(
        private _confirmationService: ConfirmationService,
        private swUpdate: SwUpdate,
    ) {
        this.reloadCache();
    }

    private reloadCache() {
        if (this.swUpdate.isEnabled) {
            this.swUpdate.versionUpdates.subscribe((evt) => {
                if (evt.type === this.NewVersionDetected_Event) {

                    this._confirmationService.info('::NewVersionAvailableUpdate', '::NewVersionAvailable', this.messagePopUpOptions)
                        .subscribe(() => {
                            window.location.reload();
                        });
                }
            });
        }
    }
}