import { Injectable } from "@angular/core";

@Injectable()
export class LocationService {
    public async getCurrentLocation(): Promise<GeolocationPosition> {
        return new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    resolve(position);
                },
                (error) => {
                    this.handleGeolocationError(error, reject);
                }
            );
        });
    }

    private handleGeolocationError(error: GeolocationPositionError, reject: (reason?: any) => void): void {
        switch (error.code) {
            case error.PERMISSION_DENIED:
                reject('::GeolocationPermissionDeniedError');
                break;
            case error.POSITION_UNAVAILABLE:
                reject('::GeolocationInformationIsUnavailableError');
                break;
            case error.TIMEOUT:
                reject('::GeolocationTimeoutError');
                break;
            default:
                reject('::GeolocationUnknownError');
                break;
        }
    }
}