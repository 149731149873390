import { Injectable, Injector } from '@angular/core';
import { SynchronizationEntity } from '../entities/synchronization-status';
import { UsersRepository } from '../repositories/users-repository';
import { SynchronizationServiceBase } from './synchronization-service-base';

@Injectable()
export class UsersSyncService extends SynchronizationServiceBase {
  constructor(injector: Injector, private readonly _usersRepository: UsersRepository) {
    super(injector);
  }

  protected entityToSync = SynchronizationEntity.Users;

  async synchronize(): Promise<void> {
    await this._usersRepository.refreshUsersExternalData();
  }
}
