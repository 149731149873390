import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable()
export class AppVersionService {
    private readonly appVersionKey = 'appVersion';

    public GetAppVersion() : string {
        return environment[this.appVersionKey];
    }
}