import { Injectable, Injector } from '@angular/core';
import { SynchronizationService } from '@proxy/synchronization';
import { firstValueFrom } from 'rxjs';
import { fields } from '../../utils/app-utils';
import {
  HourActivityReportOperator,
  OperatorMode,
} from '../entities/hour-activity-report-operator';
import { HourActivityReportOperatorToAdd } from '../entities/hour-activity-report-operator-to-add';
import { Operator } from '../entities/operator';
import { LocalDbConsts } from '../local-db-consts';
import { LocalRepositoryBase } from './local-repository-base';
import { UsersRepository } from './users-repository';

@Injectable()
export class OperatorsRepository extends LocalRepositoryBase {
  constructor(
    injector: Injector,
    private readonly _synchronizationService: SynchronizationService,
    private readonly _usersRepository: UsersRepository,
  ) {
    super(injector);
  }

  async fetchAll(): Promise<Operator[]> {
    const remoteOperators = await firstValueFrom(
      this._synchronizationService.getAllOperatorsForSynchronization(this.remoteServicesConfig),
    );
    return remoteOperators.map(
      d =>
        new Operator(
          d.id,
          d.qrCode,
          d.code,
          d.identityNumber,
          d.laborType,
          d.firstName,
          d.lastName,
          d.userName,
          d.vendorId,
        ),
    );
  }

  async saveMany(operators: Operator[]): Promise<void> {
    this._db.transaction(
      LocalDbConsts.localDbReadWriteTransaction,
      this._db.operators,
      async () => {
        await this._db.operators.clear();
        return this._db.operators.bulkPut(operators);
      },
    );
  }

  async deleteHourActivityReportOperator(id: number): Promise<void> {
    await this._db.hourActivityReportOperators
      .where(fields<HourActivityReportOperator>().id)
      .equals(id)
      .delete();
  }

  getAllOperatorsByHourActivityReportId(
    hourActivityReportId: number,
  ): Promise<HourActivityReportOperator[]> {
    return this._db.hourActivityReportOperators
      .where(fields<HourActivityReportOperator>().hourActivityReportId)
      .equals(hourActivityReportId)
      .toArray();
  }

  getOperatorsByQrCode(qrCode: string): Promise<Operator> {
    return this._db.operators.where(fields<Operator>().qrCode).equals(qrCode).first();
  }

  async existsOperator(hourActivityReportId: number, operatorId: number): Promise<boolean> {
    const operator = await this.getOperatorOfHourActivityReport(hourActivityReportId, operatorId);
    return operator !== undefined;
  }

  async getOperatorById(operatorId: number): Promise<Operator> {
    return this._db.operators.where(fields<Operator>().id).equals(operatorId).first();
  }

  async addHourActivityReportOperator(
    hourActivityReportOperator: HourActivityReportOperator,
  ): Promise<void> {
    await this._db.hourActivityReportOperators.add(hourActivityReportOperator);
  }

  getAll(): Promise<Operator[]> {
    return this._db.operators.toArray();
  }

  async getAllAsHourActivityReportOperatorToAdd(): Promise<HourActivityReportOperatorToAdd[]> {
    const currentUserVendorId = await this._usersRepository.getUserVendorId(this.currentUser?.id);
    const operatorsByVendor = await this._db.operators
      .where(fields<Operator>().vendorId)
      .equals(currentUserVendorId)
      .toArray();
    return operatorsByVendor.map(
      x =>
        new HourActivityReportOperatorToAdd(x.id, x.firstName + ' ' + x.lastName, x.identityNumber),
    );
  }

  getHourActivityReportOperatorById(operatorId: number): Promise<HourActivityReportOperator> {
    return this._db.hourActivityReportOperators
      .where(fields<HourActivityReportOperator>().operatorId)
      .equals(operatorId)
      .first();
  }

  async getOperatorOfHourActivityReport(
    hourActivityReportId: number,
    operatorId: number,
  ): Promise<HourActivityReportOperator> {
    return await this._db.hourActivityReportOperators
      .where(fields<HourActivityReportOperator>().hourActivityReportId)
      .equals(hourActivityReportId)
      .and((x: HourActivityReportOperator) => x.operatorId === operatorId)
      .first();
  }

  async confirmOperator(
    hourActivityReportId: number,
    hourActivityReportOperatorId: number,
    confirmMode: OperatorMode,
    latitude: number,
    longitude: number,
  ): Promise<boolean> {
    const operatorToConfirm = await this.getOperatorOfHourActivityReport(
      hourActivityReportId,
      hourActivityReportOperatorId,
    );
    if (operatorToConfirm.isConfirmed) {
      return true;
    }
    operatorToConfirm.confirmationMode = confirmMode;
    operatorToConfirm.isConfirmed = true;
    operatorToConfirm.endLatitude = latitude;
    operatorToConfirm.endLongitude = longitude;
    await this._db.hourActivityReportOperators.update(operatorToConfirm.id, operatorToConfirm);
    return false;
  }

  async addHourActivityReportOperatorIfDoesntExist(
    hourActivityReportOperator: HourActivityReportOperator,
  ): Promise<void> {
    this._db.transaction('rw!', this._db.hourActivityReportOperators, async () => {
      let existsOperator = await this.existsOperator(
        hourActivityReportOperator.hourActivityReportId,
        hourActivityReportOperator.operatorId,
      );
      console.error(existsOperator);
      if (!existsOperator) {
        await this.addHourActivityReportOperator(hourActivityReportOperator);
      }
    });
  }
}
