import { Injectable } from '@angular/core';
import Dexie from 'dexie';
import { ActivitySubgroup } from './entities/activity-subgroup';
import { Contract } from './entities/contract';
import { HourActivityReport } from './entities/hour-activity-report';
import { HourActivityReportActivity } from './entities/hour-activity-report-activity';
import { HourActivityReportOperator } from './entities/hour-activity-report-operator';
import { HourActivityReportSyncState } from './entities/hour-activity-report-sync-state';
import { LocalConfiguration, LocalConfigurationType } from './entities/local-configuration';
import { Location } from './entities/location';
import { Operator } from './entities/operator';
import { Process } from './entities/process';
import { SynchronizationEntity, SynchronizationStatus } from './entities/synchronization-status';
import { User } from './entities/user';
import { LocalDbConsts } from './local-db-consts';
import { Migrator } from './migrator';
import { SynchronizationFrequencies } from './synchronization/synchronization-frequencies';

@Injectable({
  providedIn: 'root',
})
export class LocalDb extends Dexie {
  localConfigurations: Dexie.Table<LocalConfiguration, [string, LocalConfigurationType]>;
  users: Dexie.Table<User, string>;
  synchronizationStatus: Dexie.Table<SynchronizationStatus, SynchronizationEntity>;
  contracts: Dexie.Table<Contract, number>;
  processes: Dexie.Table<Process, number>;
  locations: Dexie.Table<Location, number>;
  operators: Dexie.Table<Operator, number>;
  activitySubgroups: Dexie.Table<ActivitySubgroup, number>;
  hourActivityReports: Dexie.Table<HourActivityReport, number>;
  hourActivityReportOperators: Dexie.Table<HourActivityReportOperator, number>;
  hourActivityReportActivities: Dexie.Table<HourActivityReportActivity, number>;
  hourActivityReportSynchronizationState: Dexie.Table<HourActivityReportSyncState, number>;

  constructor() {
    super(LocalDbConsts.dbName);

    new Migrator(this).updateDatabase(LocalDbConsts.dbVersion);

    this.on('populate', () => this.seed());

    this.open().catch(error => console.error(error));
  }

  async seed() {
    const initialStatus = [
      new SynchronizationStatus(
        SynchronizationEntity.Contracts,
        SynchronizationFrequencies.frequencies[
          SynchronizationEntity.Contracts
        ].syncIntervalInMilliseconds(),
        true,
      ),
      new SynchronizationStatus(
        SynchronizationEntity.Processes,
        SynchronizationFrequencies.frequencies[
          SynchronizationEntity.Processes
        ].syncIntervalInMilliseconds(),
        true,
      ),
      new SynchronizationStatus(
        SynchronizationEntity.Locations,
        SynchronizationFrequencies.frequencies[
          SynchronizationEntity.Locations
        ].syncIntervalInMilliseconds(),
        true,
      ),
      new SynchronizationStatus(
        SynchronizationEntity.Operators,
        SynchronizationFrequencies.frequencies[
          SynchronizationEntity.Operators
        ].syncIntervalInMilliseconds(),
        true,
      ),
      new SynchronizationStatus(
        SynchronizationEntity.ActivitySubgroups,
        SynchronizationFrequencies.frequencies[
          SynchronizationEntity.ActivitySubgroups
        ].syncIntervalInMilliseconds(),
        true,
      ),
      new SynchronizationStatus(
        SynchronizationEntity.Users,
        SynchronizationFrequencies.frequencies[
          SynchronizationEntity.Users
        ].syncIntervalInMilliseconds(),
        true,
      ),
      new SynchronizationStatus(
        SynchronizationEntity.HourActivityReportPending,
        SynchronizationFrequencies.frequencies[
          SynchronizationEntity.HourActivityReportPending
        ].syncIntervalInMilliseconds(),
        false,
      ),
    ];

    await this.synchronizationStatus.bulkAdd(initialStatus);
  }
}
