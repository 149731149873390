
export interface ActivitySubgroupForSynchronizationDto {
  id: number;
  code?: string;
  name?: string;
  activityGroupId: number;
}

export interface ContractForSynchronizationDto {
  id: number;
  vendorId: number;
  processes: number[];
  number?: string;
  startDate?: string;
  dueDate?: string;
}

export interface LocationForSynchronizationDto {
  id: number;
  name?: string;
  latitude: number;
  longitude: number;
  regionId: number;
}

export interface OperatorForSynchronizationDto {
  id: number;
  qrCode?: string;
  code?: string;
  identityNumber?: string;
  laborType?: string;
  firstName?: string;
  lastName?: string;
  userName?: string;
  vendorId: number;
}

export interface ProcessForSynchronizationDto {
  id: number;
  name?: string;
  activitySubgroups: number[];
}
