import { Injectable, Injector } from "@angular/core";
import { SynchronizationServiceBase } from "./synchronization-service-base";
import { SynchronizationEntity } from "../entities/synchronization-status";
import { ActivitySubgroupsRepository } from "../repositories/activity-subgroups-repository";

@Injectable()
export class ActivitySubgroupsSyncService extends SynchronizationServiceBase {
    constructor(
        injector: Injector,
        private readonly _activitySubgroupsRepository: ActivitySubgroupsRepository) {
        super(injector);
    }

    protected entityToSync = SynchronizationEntity.ActivitySubgroups;

    async synchronize(): Promise<void> {
        const remoteActivitySubgroups = await this._activitySubgroupsRepository.fetchAll();
        await this._activitySubgroupsRepository.saveMany(remoteActivitySubgroups);
    }
}