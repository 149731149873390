export class LocalConfiguration {
  userId: string;
  type: LocalConfigurationType;
  data: any;

  constructor(userId: string, type: LocalConfigurationType, data: any) {
    this.userId = userId;
    this.type = type;
    this.data = data;
  }
}

export enum LocalConfigurationType {
  ApplicationConfiguration,
  ApplicationLocalization,
}
