import { ApiInterceptor, HttpWaitService } from '@abp/ng.core';
import { HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, catchError } from 'rxjs';
import { LocalStorageConsts } from '../app-consts';
import { AppPaths } from '../app-paths';

/// <summary>
/// This interceptor is used to intercept the logout request and remove the user from the local storage.
/// </summary>
@Injectable({
  providedIn: 'root',
})
export class LogoutInterceptor extends ApiInterceptor {
  constructor(private _router: Router, httpService: HttpWaitService) {
    super(httpService);
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!request.url.includes(AppPaths.LogoutUrlToIntercept)) {
      return super.intercept(request, next);
    }

    if (request.url.includes(AppPaths.LogoutUrlToIntercept)) {
      this.removeUserFromLocalStorage();
      return super.intercept(request, next).pipe(
        catchError(_ => {
          this._router.navigateByUrl('/');
          return next.handle(request);
        }),
      );
    }
  }

  private removeUserFromLocalStorage() {
    localStorage.removeItem(LocalStorageConsts.CurrentUserKey);
    localStorage.removeItem(LocalStorageConsts.AccessTokenKey);
    localStorage.removeItem(LocalStorageConsts.RefreshTokenKey);
  }
}
