export class ActivitySubgroup {
    id: number;
    code: string;
    name: string;
    activityGroupId: number;

    constructor(id: number, code: string, name: string, activityGroupId: number) {
        this.id = id;
        this.code = code;
        this.name = name;
        this.activityGroupId = activityGroupId;
    }
}