import { Injectable, Injector } from "@angular/core";
import { SynchronizationServiceBase } from "./synchronization-service-base";
import { SynchronizationEntity } from "../entities/synchronization-status";
import { OperatorsRepository } from "../repositories/operators-repository";

@Injectable()
export class OperatorsSyncService extends SynchronizationServiceBase {
    constructor(
        injector: Injector,
        private readonly _operatorsRepository: OperatorsRepository) {
        super(injector);
    }

    protected entityToSync = SynchronizationEntity.Operators;

    async synchronize(): Promise<void> {
        const remoteOperators = await this._operatorsRepository.fetchAll();
        await this._operatorsRepository.saveMany(remoteOperators);
    }
}