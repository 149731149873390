export class AppPaths {
  static readonly StatusMonitor = 'synchronization/status-monitor';
  static readonly HourActivityReport = '/hour-activity-report/';
  static readonly OperatorsStep = 'operators-step';
  static readonly QrScanner = 'scanner';
  static readonly OperatorsStepFullPath = AppPaths.HourActivityReport + AppPaths.OperatorsStep;
  static readonly QrScannerFullPath = AppPaths.HourActivityReport + AppPaths.QrScanner;
  static readonly AddProcess = 'hour-activity-report/processes';
  static readonly AddLocation = 'hour-activity-report/locations';
  static readonly AddContracts = 'hour-activity-report/contracts';
  static readonly CreationWizard = 'creation-wizard';
  static readonly CreationWizardFullPath = AppPaths.HourActivityReport + AppPaths.CreationWizard;
  static readonly AddOperatorsModal = 'manual';
  static readonly AddOperatorsModalFullPath =
    this.OperatorsStepFullPath + '/' + this.AddOperatorsModal;
  static readonly NetworkConnectionCheckUrl = '/api/app/application-health-check/check-health';
  static readonly LogoutUrlToIntercept = '/connect/revocat';
  static readonly ViewReport = 'view';
  static readonly ViewReportFullPath = AppPaths.HourActivityReport + AppPaths.ViewReport;
  static readonly ConfirmOperatorWithQr = 'confirm-operator';
  static readonly ConfirmOperatorWithQrFullPath =
    AppPaths.HourActivityReport + AppPaths.ConfirmOperatorWithQr;
  static readonly About = 'about';
  static readonly Help = 'help';
  static readonly HourActivityReportSynchronizationMonitor =
    'synchronization/hour-activity-report-sync';
}
