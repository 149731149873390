import {
  AbpApplicationConfigurationService,
  ApplicationConfigurationDto,
  ApplicationConfigurationRequestOptions,
  RestService,
} from '@abp/ng.core';
import { Injectable } from '@angular/core';
import { Observable, from, map, tap } from 'rxjs';
import { LocalStorageConsts } from '../app-consts';
import { LocalConfigurationType } from '../local-db/entities/local-configuration';
import { LocalConfigurationRepository } from '../local-db/repositories/local-configuration-repository';
import { AppUtils } from '../utils/app-utils';

@Injectable({
  providedIn: 'root',
})
export class CustomApplicationConfigurationService {
  private readonly _abpApplicationConfigurationService: AbpApplicationConfigurationService;

  constructor(
    restService: RestService,
    private readonly _localConfigurationRepository: LocalConfigurationRepository,
  ) {
    this._abpApplicationConfigurationService = new AbpApplicationConfigurationService(restService);
  }

  get: (
    options: ApplicationConfigurationRequestOptions,
  ) => Observable<ApplicationConfigurationDto> = options => {
    const instance = this as CustomApplicationConfigurationService;
    const user = JSON.parse(localStorage.getItem(LocalStorageConsts.CurrentUserKey));

    if (window.navigator.onLine) {
      // TODO: use network connection service
      return instance._abpApplicationConfigurationService.get(options).pipe(
        tap(response => {
          instance._localConfigurationRepository.set(
            response?.currentUser?.id ?? '',
            LocalConfigurationType.ApplicationConfiguration,
            response,
          );
          if (!AppUtils.emptyString(response?.currentUser?.id)) {
            localStorage.setItem(
              LocalStorageConsts.CurrentUserKey,
              JSON.stringify(response.currentUser),
            );
          } else {
            localStorage.removeItem(LocalStorageConsts.CurrentUserKey);
          }
        }),
      );
    } else {
      return from(
        instance._localConfigurationRepository.get(
          user?.id ?? '',
          LocalConfigurationType.ApplicationConfiguration,
        ),
      ).pipe(map(response => response.data as ApplicationConfigurationDto));
    }
  };
}
