export class Location {
  id: number;
  name: string;
  latitude: number;
  longitude: number;
  regionId: number;

  constructor(id: number, name: string, latitude: number, longitude: number, regionId: number) {
    this.id = id;
    this.name = name;
    this.latitude = latitude;
    this.longitude = longitude;
    this.regionId = regionId;
  }
}
