import { mapEnumToOptions } from '@abp/ng.core';

export enum HourActivityReportState {
  InProgress = 0,
  PartiallyIntegrated = 1,
  Finished = 2,
  Integrated = 3,
}

export const hourActivityReportStateOptions = mapEnumToOptions(HourActivityReportState);
