import { Injectable, Injector } from '@angular/core';
import { SynchronizationService } from '@proxy/synchronization';
import { firstValueFrom } from 'rxjs';
import { Contract } from '../entities/contract';
import { LocalDbConsts } from '../local-db-consts';
import { LocalRepositoryBase } from './local-repository-base';

@Injectable()
export class ContractsRepository extends LocalRepositoryBase {
  constructor(
    injector: Injector,
    private readonly _synchronizationService: SynchronizationService,
  ) {
    super(injector);
  }

  async fetchAll(): Promise<Contract[]> {
    const remoteContracts = await firstValueFrom(
      this._synchronizationService.getAllContractsForSynchronization(this.remoteServicesConfig),
    );
    return remoteContracts.map(
      d =>
        new Contract(
          d.id,
          d.vendorId,
          d.processes,
          d.number,
          new Date(d.startDate),
          new Date(d.dueDate),
        ),
    );
  }

  async saveMany(contracts: Contract[]): Promise<void> {
    this._db.transaction(
      LocalDbConsts.localDbReadWriteTransaction,
      this._db.contracts,
      async () => {
        await this._db.contracts.clear();
        return this._db.contracts.bulkPut(contracts);
      },
    );
  }

  getAll(): Promise<Contract[]> {
    return this._db.contracts.toArray();
  }

  async getAllValidContractsFromVendor(vendorId: number): Promise<Contract[]> {
    let contracts = await this.getAll();
    contracts = contracts.filter(x => x.vendorId === vendorId);
    return contracts;
  }

  async getAllByProcess(processId: number, vendorId: number): Promise<Contract[]> {
    let contracts = await this.getAll();
    contracts = contracts.filter(x => x.processes.includes(processId) && x.vendorId === vendorId);
    const activeContracts = contracts.filter(x => x.startDate <= new Date() && x.dueDate >= new Date());

    if (activeContracts.length > 0) {
      return activeContracts;
    } else {
      contracts.sort((a, b) => b.dueDate.getTime() - a.dueDate.getTime());
      return [contracts[0]];
    }
  }

  getById(id: number): Promise<Contract> {
    return this._db.contracts.get(id);
  }
}
