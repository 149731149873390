<div class="d-inline-block float-end pe-3">
  <div [hidden]="!networkStatus">
    <i *ngIf="showSyncWarning" class="fas fa-exclamation-triangle text-warning icon-marging"></i>
    <span><img src="assets/images/network/online.svg" alt="{{ '::Connected' | abpLocalization }}" /></span>
    <span class="ml-2"> {{ '::Connected' | abpLocalization }} </span>
  </div>
  <div [hidden]="networkStatus">
    <i *ngIf="showSyncWarning" class="fas fa-exclamation-triangle text-warning icon-marging"></i>
    <span><img src="assets/images/network/offline.svg" alt="{{ '::OfflineMode' | abpLocalization }}" /></span>
    <span class="ml-2"> {{ '::OfflineMode' | abpLocalization }} </span>
  </div>
</div>