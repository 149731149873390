import { Injectable, Injector } from '@angular/core';
import { LocalConfiguration, LocalConfigurationType } from '../entities/local-configuration';
import { LocalDb } from '../local-db';

@Injectable()
export class LocalConfigurationRepository {
  private readonly _db: LocalDb;
  constructor(injector: Injector) {
    this._db = injector.get(LocalDb);
  }

  get(userId: string, type: LocalConfigurationType): Promise<LocalConfiguration> {
    return this._db.localConfigurations.get([userId, type]);
  }

  set(userId: string, type: LocalConfigurationType, data: any): void {
    this._db.localConfigurations.put(new LocalConfiguration(userId, type, data), [userId, type]);
  }
}
