import { Injectable, Injector } from '@angular/core';
import { SynchronizationService } from '@proxy/synchronization';
import { firstValueFrom } from 'rxjs';
import { Process } from '../entities/process';
import { LocalDbConsts } from '../local-db-consts';
import { LocalRepositoryBase } from './local-repository-base';
import { flattenList } from '../../utils/app-utils';
import { ContractsRepository } from './contracts-repository';

@Injectable()
export class ProcessesRepository extends LocalRepositoryBase {
  constructor(
    injector: Injector,
    private readonly _synchronizationService: SynchronizationService,
    private readonly _contractsRepository: ContractsRepository,
  ) {
    super(injector);
  }

  async fetchAll(): Promise<Process[]> {
    const remoteProcesses = await firstValueFrom(
      this._synchronizationService.getAllProcessesForSynchronization(this.remoteServicesConfig),
    );
    return remoteProcesses.map(d => new Process(d.id, d.name, d.activitySubgroups));
  }

  async saveMany(processes: Process[]): Promise<void> {
    this._db.transaction(
      LocalDbConsts.localDbReadWriteTransaction,
      this._db.processes,
      async () => {
        await this._db.processes.clear();
        return this._db.processes.bulkPut(processes);
      },
    );
  }

  getAll(): Promise<Process[]> {
    return this._db.processes.toArray();
  }

  async getAllWithAtLeastOneContract(vendorId: number): Promise<Process[]> {
    const contractsPromise = this._contractsRepository.getAllValidContractsFromVendor(vendorId); 
    const processesPromise = this._db.processes.toArray();
    const contracts = await contractsPromise;
    const processes = await processesPromise;
    const processIdsWithAContract = new Set<number>(flattenList(contracts.map(x => x.processes)));
    return processes.filter(x => processIdsWithAContract.has(x.id));
  }

  getById(id: number): Promise<Process> {
    return this._db.processes.get(id);
  }
}
