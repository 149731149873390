import { ConfigStateService, CurrentUserDto, LocalizationService } from '@abp/ng.core';
import { Confirmation, ConfirmationService, ToasterService } from '@abp/ng.theme.shared';
import { Injector } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { LoggingService } from './services/logging-service';
import { AppUtils } from './utils/app-utils';

export abstract class AppComponentBase {
  private readonly _localization: LocalizationService;
  private readonly _configStateService: ConfigStateService;
  private readonly _spinner: NgxSpinnerService;
  protected readonly _titleService: Title;
  readonly _confirmation: ConfirmationService;
  readonly _toaster: ToasterService;
  readonly _log: LoggingService;
  protected readonly Router: Router;

  public messagePopUpOptions: Partial<Confirmation.Options> = {
    hideCancelBtn: true,
    hideYesBtn: true,
    dismissible: true,
  };

  constructor(injector: Injector) {
    this._localization = injector.get(LocalizationService);
    this._configStateService = injector.get(ConfigStateService);
    this._spinner = injector.get(NgxSpinnerService);
    this._confirmation = injector.get(ConfirmationService);
    this._toaster = injector.get(ToasterService);
    this._titleService = injector.get(Title);
    this._log = injector.get(LoggingService);
    this.Router = injector.get(Router);
  }

  protected l(key: string, ...args: any[]): string {
    args.unshift(key);

    let localizedText = this._localization.instant(key, ...args);
    if (!localizedText) {
      localizedText = key;
    }

    return localizedText;
  }

  protected get currentUser(): CurrentUserDto {
    let user: CurrentUserDto;
    try {
      user = this._configStateService.getDeep('currentUser');
      if (AppUtils.emptyString(user?.id)) {
        throw new Error('User not found');
      }
    } catch (error) {
      this._log.error(error);
      user = JSON.parse(localStorage.getItem('currentUser'));
    }
    return user;
  }

  showMainSpinner(): void {
    this._spinner.show();
  }

  hideMainSpinner(): void {
    this._spinner.hide();
  }

  isNullOrUndefined(value: any): boolean {
    return value === undefined || value === null;
  }

  goToHome() {
    this.Router.navigate(['/']);
  }
}
