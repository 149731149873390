import { Component, Injector, OnInit } from '@angular/core';
import { AppComponentBase } from 'src/app/shared/app-component-base';
import { HealthCheckService } from 'src/app/shared/services/HealthCheckService';
import { HourActivityReportSyncStateRepository } from '../../local-db/repositories/hour-activity-report-sync-state-repository';
import { ReportSenderService } from '../../local-db/synchronization/reportSender.service';

@Component({
  selector: 'app-network-status-notification',
  templateUrl: './network-status-notification.component.html',
  styleUrls: ['./network-status-notification.component.scss']
})
export class NetworkStatusNotificationComponent extends AppComponentBase implements OnInit {
  networkStatus: boolean;
  showSyncWarning: boolean;

  constructor(
    _injector: Injector,
    private _networkServiceCheck: HealthCheckService,
    private readonly _hourActivityReportsSyncRepository: HourActivityReportSyncStateRepository,
    private readonly _ReportSenderService: ReportSenderService,
  ) {
    super(_injector);

    this._ReportSenderService.onSynchronized
      .subscribe(() => {
        this.areSomeSynchronizationStatusNotOk();
      });
  }

  async ngOnInit(): Promise<void> {
    this._networkServiceCheck.heartBeat$.subscribe(status => {
      this.networkStatus = status;
    });
    this.areSomeSynchronizationStatusNotOk();
  }

  private async areSomeSynchronizationStatusNotOk() {
    this.showSyncWarning = await this._hourActivityReportsSyncRepository.areSomeSynchronizationStatusNotOk();
  }
}
