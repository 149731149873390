import { Environment } from '@abp/ng.core';

const baseUrl = 'https://hour-activity-report-test.upmuruguay.net';

const oAuthConfig = {
  issuer: 'https://hour-activity-report-backend-test.upmuruguay.net/',
  redirectUri: baseUrl,
  clientId: 'HourReportEntry_App',
  responseType: 'code',
  scope: 'offline_access HourReportEntry',
  requireHttps: true,
  postLogoutRedirectUri:
    'https://login.microsoftonline.com/9eab37f0-91c6-47e3-9c00-fe8544bd272e/oauth2/v2.0/logout?post_logout_redirect_uri=https://hour-activity-report-test.upmuruguay.net',
};

export const environment = {
  name: 'testing',
  production: false,
  application: {
    baseUrl,
    name: 'HourReportEntry-Test',
  },
  oAuthConfig,
  apis: {
    default: {
      url: 'https://hour-activity-report-backend-test.upmuruguay.net',
      rootNamespace: 'Upm.HourReportEntry',
    },
    AbpAccountPublic: {
      url: oAuthConfig.issuer,
      rootNamespace: 'AbpAccountPublic',
    },
  },
  applicationInsights: {
    isEnabled: false,
    instrumentationKey: '3d6f0ba7-005e-4dc7-9322-0980e7af1082'
  },
  appVersion: '1.8.0',
  grafana: {
    isEnabled: true,
    url: 'https://faro-collector-prod-sa-east-1.grafana.net/collect/68b9bb6b5fb143df836bf14095dc5c92',
  },
} as Environment;
