import type { HourActivityReportState } from '../enums/hour-activity-report-state.enum';
import type { HourActivityReportProgressState } from '../enums/hour-activity-report-progress-state.enum';
import type { DeviceType } from './device-type.enum';

export interface HourActivityReportActivityFrontendDto {
  startTime?: Date;
  endTime?: Date;
  activitySubGroupExternalId: number;
  locationId: number;
}

export interface HourActivityReportFrontendDto {
  id?: string;
  startDate?: Date;
  state: HourActivityReportState;
  progressState: HourActivityReportProgressState;
  traveledDistance: number;
  observations?: string;
  processExternalId: number;
  locationExternalId: number;
  contractExternalId: number;
  vendorId: number;
  userExternalId?: string;
  endLocationExternalId: number;
  operators: HourActivityReportOperatorFrontendDto[];
  activities: HourActivityReportActivityFrontendDto[];
  number?: string;
  deviceType: DeviceType;
  regionId: number;
}

export interface HourActivityReportOperatorFrontendDto {
  operatorExternalId: number;
  operatorCode?: string;
  source: number;
  startLatitude: number;
  startLongitude: number;
  endLatitude: number;
  endLongitude: number;
  isManuallyConfirmed: boolean;
  qrCode?: string;
}

export interface UpdateOrCreateHourActivityReportOutputDto {
  success: boolean;
  message?: string;
  reportId?: string;
  hasStateInformation: boolean;
  reportState?: HourActivityReportState;
}
