import {
  AbpApplicationLocalizationService,
  ApplicationLocalizationDto,
  ApplicationLocalizationRequestDto,
  RestService,
} from '@abp/ng.core';
import { Injectable } from '@angular/core';
import { Observable, from, map, tap } from 'rxjs';
import { LocalConfigurationType } from '../local-db/entities/local-configuration';
import { LocalConfigurationRepository } from '../local-db/repositories/local-configuration-repository';
import { LocalStorageConsts } from '../app-consts';

@Injectable({
  providedIn: 'root',
})
export class CustomApplicationLocalizationService {
  private readonly _abpApplicationLocalizationService: AbpApplicationLocalizationService;

  constructor(
    restService: RestService,
    private readonly _localConfigurationRepository: LocalConfigurationRepository,
  ) {
    this._abpApplicationLocalizationService = new AbpApplicationLocalizationService(restService);
  }

  get: (options: ApplicationLocalizationRequestDto) => Observable<ApplicationLocalizationDto> =
    options => {
      const instance = this as CustomApplicationLocalizationService;
      const user = JSON.parse(localStorage.getItem(LocalStorageConsts.CurrentUserKey));

      if (window.navigator.onLine) {
        return instance._abpApplicationLocalizationService
          .get(options)
          .pipe(
            tap(response =>
              instance._localConfigurationRepository.set(
                user?.id ?? '',
                LocalConfigurationType.ApplicationLocalization,
                response,
              ),
            ),
          );
      } else {
        return from(
          instance._localConfigurationRepository.get(
            user?.id ?? '',
            LocalConfigurationType.ApplicationLocalization,
          ),
        ).pipe(map(response => response.data as ApplicationLocalizationDto));
      }
    };
}
