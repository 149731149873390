import { NgbTimeStruct } from "@ng-bootstrap/ng-bootstrap";
import { Tuple } from "./tuple";

export class AppUtils {
  static isDefinedAndPositiveNumber(param: number): boolean {
    return param !== null && param !== undefined && param > 0;
  }

  static emptyString(value: string): boolean {
    return (
      value === undefined || value === null || value === '' || value.toString().trim().length === 0
    );
  }

  static textSearchComparator(value: string, filter: string): boolean {
    return (
      value?.toLocaleLowerCase()?.trim()?.includes(filter?.toLocaleLowerCase()?.trim()) ?? false
    );
  }

  static toPaddedString(value: number) {
    return value.toString().padStart(2, '0');
  }

  static isDefinedNumber(param: number): boolean {
    return param !== null && param !== undefined;
  }

  static isDateSameDayOrBeforeThanCurrent(inputDate: Date): boolean {
    const inputYear = inputDate.getFullYear();
    const inputMonth = inputDate.getMonth();
    const inputDay = inputDate.getDate();

    const nowDate = new Date();
    const nowYear = nowDate.getFullYear();
    const nowMonth = nowDate.getMonth();
    const nowDay = nowDate.getDate();

    return inputYear < nowYear ||
      (inputYear === nowYear && inputMonth < nowMonth) ||
      (inputYear === nowYear && inputMonth === nowMonth && inputDay <= nowDay);
  }

  static compareNgbTimes(time1: NgbTimeStruct, time2: NgbTimeStruct): number {
    const seconds1 = time1.hour * 3600 + time1.minute * 60 + time1.second;
    const seconds2 = time2.hour * 3600 + time2.minute * 60 + time2.second;
  
    if (seconds1 > seconds2) {
      return 1;
    } else if (seconds1 < seconds2) {
      return -1;
    } else {
      return 0;
    }
  }

  static isAfterNgbTime(time1: NgbTimeStruct, time2: NgbTimeStruct) {
    return this.compareNgbTimes(time1, time2) == 1;
  }

  static addNgbTime(x: NgbTimeStruct, y: NgbTimeStruct): NgbTimeStruct {
    let second = x.second + y.second;
    let minute = x.minute + y.minute;
    let hour = x.hour + y.hour;
  
    if (second >= 60) {
      minute += Math.floor(second / 60);
      second %= 60;
    }
  
    if (minute >= 60) {
      hour += Math.floor(minute / 60);
      minute %= 60;
    }
  
    if (hour >= 24) {
      hour %= 24;
    }
  
    return {hour, minute, second};
  }
  

  static innerJoin<TLeft, TRight, TKey>(left: TLeft[],
    right: TRight[],
    onLeft: (x: TLeft) => TKey,
    onRight: (x: TRight) => TKey): Tuple<TLeft, TRight>[] {
      const result: Tuple<TLeft, TRight>[] = [];

      left.forEach((leftItem) => {
        const leftKey = onLeft(leftItem);
        const matchingRightItems = right.filter((rightItem) => onRight(rightItem) === leftKey);
    
        matchingRightItems.forEach((matchingRightItem) => {
          result.push(new Tuple(leftItem, matchingRightItem));
        });
      });
    
      return result;
  }

  static isEmptyOrUndefined<T>(list: T[]) {
    return list === undefined || list === null || list.length === 0;
  }
}

export function flattenList<T>(xs: T[][]): T[] {
  return xs.reduce((acc, val) => acc.concat(val), []);
}


/// <summary>
/// Returns a proxy object that returns the name of T's properties as string.
/// </summary>
export function fields<T>() {
  return new Proxy(
    {},
    {
      get: function (_target, prop, _receiver) {
        return prop;
      },
    },
  ) as {
      [P in keyof T]: P;
    };
}