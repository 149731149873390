import { NgModule } from '@angular/core';
import { SharedModule } from '../shared.module';
import { ActivitiesRepository } from './repositories/activities-repository';
import { ActivitySubgroupsRepository } from './repositories/activity-subgroups-repository';
import { ContractsRepository } from './repositories/contracts-repository';
import { HourActivityReportRepository } from './repositories/hour-activity-report-repository';
import { HourActivityReportSyncStateRepository } from './repositories/hour-activity-report-sync-state-repository';
import { LocalConfigurationRepository } from './repositories/local-configuration-repository';
import { LocationsRepository } from './repositories/locations-repository';
import { OperatorsRepository } from './repositories/operators-repository';
import { ProcessesRepository } from './repositories/processes-repository';
import { SynchronizationStatusRepository } from './repositories/synchronization-status-repository';
import { UsersRepository } from './repositories/users-repository';
import { ActivitySubgroupsSyncService } from './synchronization/activity-subgroups-sync-service';
import { ContractsSyncService } from './synchronization/contracts-sync-service';
import { LocationsSyncService } from './synchronization/locations-sync-service';
import { OperatorsSyncService } from './synchronization/operators-sync-service';
import { ProcessesSyncService } from './synchronization/processes-sync-service';
import { ReportSenderService } from './synchronization/reportSender.service';
import { UsersSyncService } from './synchronization/users-sync-service';

@NgModule({
  providers: [
    LocalConfigurationRepository,
    UsersRepository,
    SynchronizationStatusRepository,
    ContractsRepository,
    ContractsSyncService,
    ProcessesRepository,
    ProcessesSyncService,
    LocationsRepository,
    LocationsSyncService,
    OperatorsRepository,
    OperatorsSyncService,
    ActivitySubgroupsRepository,
    ActivitySubgroupsSyncService,
    HourActivityReportRepository,
    ActivitiesRepository,
    HourActivityReportSyncStateRepository,
    ReportSenderService,
    UsersSyncService,
  ],
  imports: [SharedModule],
})
export class LocalDbModule {}
