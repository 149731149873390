import { Transaction } from 'dexie';
import { fields } from '../utils/app-utils';
import { ActivitySubgroup } from './entities/activity-subgroup';
import { Contract } from './entities/contract';
import { HourActivityReport } from './entities/hour-activity-report';
import { HourActivityReportActivity } from './entities/hour-activity-report-activity';
import { HourActivityReportOperator } from './entities/hour-activity-report-operator';
import { HourActivityReportSyncState } from './entities/hour-activity-report-sync-state';
import { LocalConfiguration } from './entities/local-configuration';
import { Location } from './entities/location';
import { Operator } from './entities/operator';
import { Process } from './entities/process';
import { SynchronizationEntity, SynchronizationStatus } from './entities/synchronization-status';
import { User } from './entities/user';
import { LocalDb } from './local-db';
import { SynchronizationFrequencies } from './synchronization/synchronization-frequencies';

export class Migrator {
  private readonly _db: LocalDb;
  private readonly _migrations = migrations;

  constructor(db: LocalDb) {
    this._db = db;
  }

  async updateDatabase(versionNumber?: number) {
    let migrationsToRun = this._migrations;

    if (versionNumber) {
      migrationsToRun = migrationsToRun.filter(m => m.versionNumber <= versionNumber);
    }

    migrationsToRun = migrationsToRun.sort((a, b) => a.versionNumber - b.versionNumber);

    for (const migration of migrationsToRun) {
      this._db
        .version(migration.versionNumber)
        .stores(migration.stores)
        .upgrade(migration.upgrader);
    }
  }
}

export class DexieMigration {
  versionNumber = 1;
  stores: { [tableName: string]: string } = {};
  upgrader: (trans: Transaction) => void;

  constructor(
    version: number,
    stores: { [tableName: string]: string },
    upgrader: (trans: Transaction) => void = () => {},
  ) {
    this.versionNumber = version;
    this.stores = stores;
    this.upgrader = upgrader;
  }
}

export const migrations: DexieMigration[] = [
  new DexieMigration(1, {
    [fields<LocalDb>().localConfigurations]: `[${fields<LocalConfiguration>().userId}+${
      fields<LocalConfiguration>().type
    }]`,
    [fields<LocalDb>().users]: `&${fields<User>().id}`,
    [fields<LocalDb>().synchronizationStatus]: `&${fields<SynchronizationStatus>().entity}`,
    [fields<LocalDb>().contracts]: `&${fields<Contract>().id}`,
    [fields<LocalDb>().processes]: `&${fields<Process>().id}`,
    [fields<LocalDb>().locations]: `&${fields<Location>().id}`,
    [fields<LocalDb>().operators]: `&${fields<Operator>().id}, ${fields<Operator>().qrCode}`,
    [fields<LocalDb>().activitySubgroups]: `&${fields<ActivitySubgroup>().id}`,
    [fields<LocalDb>().hourActivityReports]: `++${fields<HourActivityReport>().id}, ${
      fields<HourActivityReport>().startDate
    }`,
    [fields<LocalDb>().hourActivityReportOperators]: `++${
      fields<HourActivityReportOperator>().id
    }, ${fields<HourActivityReportOperator>().operatorId}, ${
      fields<HourActivityReportOperator>().hourActivityReportId
    }`,
    [fields<LocalDb>().hourActivityReportActivities]: `++${
      fields<HourActivityReportActivity>().id
    }, ${fields<HourActivityReportActivity>().hourActivityReportId}, ${
      fields<HourActivityReportActivity>().activitySubgroupId
    }`,
    [fields<LocalDb>().hourActivityReportSynchronizationState]: `++${
      fields<HourActivityReportSyncState>().id
    }, ${fields<HourActivityReportSyncState>().hourActivityReportId}, ${
      fields<HourActivityReportSyncState>().synchronizationState
    }`,
  }),
  new DexieMigration(2, {}, trans => {
    trans
      .table(fields<LocalDb>().synchronizationStatus)
      .put(
        new SynchronizationStatus(
          SynchronizationEntity.Users,
          SynchronizationFrequencies.frequencies[
            SynchronizationEntity.Users
          ].syncIntervalInMilliseconds(),
          true,
        ),
      );
  }),
  new DexieMigration(3, {
    [fields<LocalDb>().operators]: `&${fields<Operator>().id}, ${fields<Operator>().qrCode}, ${fields<Operator>().vendorId}`,
  }),
];
