import { mapEnumToOptions } from '@abp/ng.core';

export enum HourActivityReportProgressState {
  ProcessSelected = 0,
  PropertySelected = 1,
  ContractSelected = 2,
  OperatorsLoaded = 3,
  StartDateSelected = 4,
  Confirmed = 5,
  Finishing = 6,
  Finished = 7,
}

export const hourActivityReportProgressStateOptions = mapEnumToOptions(HourActivityReportProgressState);
