import { Injectable, Injector } from "@angular/core";
import { LocalRepositoryBase } from "./local-repository-base";
import { HourActivityReportSyncState } from "../entities/hour-activity-report-sync-state";
import { HourActivityReport } from "../entities/hour-activity-report";
import { AppUtils, fields } from "../../utils/app-utils";
import { SynchronizationState } from "../entities/synchronization-status";
import { HourActivityReportState } from "@proxy/enums";

@Injectable()
export class HourActivityReportSyncStateRepository extends LocalRepositoryBase {
  constructor(injector: Injector) {
    super(injector);
  }

  async update(hourActivityReportSynchronizationState: HourActivityReportSyncState): Promise<any> {
    return this._db.hourActivityReportSynchronizationState.update(hourActivityReportSynchronizationState.id, hourActivityReportSynchronizationState);
  }

  async create(hourActivityReportSynchronizationState: HourActivityReportSyncState): Promise<number> {
    return this._db.hourActivityReportSynchronizationState.add(hourActivityReportSynchronizationState);
  }

  async getAll(): Promise<HourActivityReportSyncState[]> {
    return this._db.hourActivityReportSynchronizationState.toArray();
  }

  async getByHourActivityReportId(reportId: number): Promise<HourActivityReportSyncState> {
    return this._db.hourActivityReportSynchronizationState
      .where(fields<HourActivityReportSyncState>().hourActivityReportId).equals(reportId).first();
  }

  async getAllError(): Promise<HourActivityReportSyncState[]> {
    return this._db.hourActivityReportSynchronizationState
      .where(fields<HourActivityReportSyncState>().synchronizationState)
      .notEqual(SynchronizationState.Ok).toArray();
  }

  async joinHourActivityReports(reports: HourActivityReport[], state: HourActivityReportState): Promise<HourActivityReport[]> {
    const syncStates = await this.getAll();
    const joinedValues = AppUtils.innerJoin(reports, syncStates, x => x.id, x => x.hourActivityReportId);
    const filteredReports = joinedValues
      .filter((x) => {
        if (state !== undefined) {
          return this.mapReportStateToBackendReportState(x.first.state) === state;
        }
        return true;
      })
      .map(x => {
        x.first.synchronizationStatus = x.second;
        return x.first;
      });

    return filteredReports;
  }

  async areSomeSynchronizationStatusNotOk(): Promise<boolean> {
    const syncStates = await this.getAllError();
    return syncStates.length > 0;
  }
}