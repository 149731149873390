import { Injectable } from '@angular/core';
import { Observable, Subject, concatAll, interval, map, startWith, tap } from 'rxjs';
import { LocalStorageConsts } from '../app-consts';
import { HealthCheckServiceProxy } from '../service-proxies/HealthCheckServiceProxy';
import { AppUtils } from '../utils/app-utils';

@Injectable()
export class HealthCheckService {
  heartBeat$: Observable<boolean>;

  constructor(private serviceProxy: HealthCheckServiceProxy) {
    this.heartBeat$ = new Subject();
  }

  setBackendHeartbeatTimeout(heartbeatTime: number) {
    this.heartBeat$ = interval(heartbeatTime).pipe(
      map<number, Observable<boolean>>(this.serviceProxy.checkHealth.bind(this.serviceProxy)),
      concatAll(),
      tap(this.saveStatus),
      startWith(this.loadStatus()),
    );
  }

  loadStatus(): boolean {
    const storedStatus = localStorage.getItem(LocalStorageConsts.HealthCheckKey);
    if (AppUtils.emptyString(storedStatus)) {
      return navigator.onLine;
    } else {
      return storedStatus === 'true';
    }
  }
  saveStatus(x: boolean): void {
    localStorage.setItem(LocalStorageConsts.HealthCheckKey, x ? 'true' : 'false');
  }
}
