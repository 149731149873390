import { ConfigStateService, CurrentUserDto, LocalizationService } from '@abp/ng.core';
import { Injector } from '@angular/core';
import { AppUtils } from '../utils/app-utils';
import { AppConsts } from '../app-consts';
import { Confirmation, ConfirmationService } from '@abp/ng.theme.shared';
import { HourActivityReportState } from '../local-db/entities/hour-activity-report-state';
import {
  HourActivityReportState as RemoteState,
} from '@proxy/enums';

export abstract class ServiceBase {
  private readonly _localization: LocalizationService;
  private readonly _configStateService: ConfigStateService;
  readonly _confirmation: ConfirmationService;

  constructor(injector: Injector) {
    this._localization = injector.get(LocalizationService);
    this._configStateService = injector.get(ConfigStateService);
    this._confirmation = injector.get(ConfirmationService);
  }

  protected l(key: string, ...args: any[]): string {
    args.unshift(key);

    let localizedText = this._localization.instant(key, ...args);
    if (!localizedText) {
      localizedText = key;
    }

    return localizedText;
  }

  protected get currentUser(): CurrentUserDto {
    let user: CurrentUserDto;
    try {
      user = this._configStateService.getDeep('currentUser');
      if (AppUtils.emptyString(user?.id)) {
        throw new Error('User not found');
      }
    } catch (error) {
      user = JSON.parse(localStorage.getItem('currentUser'));
    }
    return user;
  }

  /// <summary>
  /// Await this function to delay code execution by ms milliseconds.
  /// </summary>
  protected sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  protected isMobile(): boolean {
    const userAgentData = navigator['userAgentData']; // Not supported by all browsers
    return userAgentData?.mobile ?? this.isMobileUserAgent();
  }

  private isMobileUserAgent(): boolean {
    let check = false;
    (function (a) {
      if (
        AppConsts.MobileUserAgentRegex.test(a) ||
        AppConsts.MobileUserAgentRegexForSubstring.test(a.substring(0, 4))
      )
        check = true;
    })(navigator.userAgent || navigator.vendor);
    return check;
  }

  public messagePopUpOptions: Partial<Confirmation.Options> = {
    hideCancelBtn: true,
    hideYesBtn: true,
    dismissible: true,
  };

  mapReportStateToBackendReportState(reportState: HourActivityReportState): number {
    switch (reportState) {
      case HourActivityReportState.Integrated: {
        return RemoteState.Integrated;
      }
      case HourActivityReportState.PartiallyIntegrated: {
        return RemoteState.PartiallyIntegrated;
      }
      case HourActivityReportState.Finished: {
        return RemoteState.Finished;
      }
      case HourActivityReportState.InProgress: {
        return RemoteState.InProgress;
      }
    }
  }
}
