import { Injectable, Injector } from '@angular/core';
import { UsersService } from '@proxy/users';
import { firstValueFrom } from 'rxjs';
import { HashingHelper } from '../../helpers/hashing-helper';
import { AppUtils } from '../../utils/app-utils';
import { User } from '../entities/user';
import { LocalRepositoryBase } from './local-repository-base';

@Injectable()
export class UsersRepository extends LocalRepositoryBase {
  constructor(injector: Injector, private readonly userService: UsersService) {
    super(injector);
  }

  async saveCurrentUserIfNeeded() {
    const userDto = this.currentUser;
    if (AppUtils.emptyString(userDto?.id)) {
      return;
    }

    const user = await this._db.users.get(userDto.id);
    if (user === undefined || user.vendorId === undefined || user.pin === undefined) {
      const externalUserData = await firstValueFrom(
        this.userService.getExternalUserDataByUsernameByUsername(
          userDto.userName,
          this.remoteServicesConfig,
        ),
      );
      const vendorId = externalUserData.vendorId;
      let pin: string = undefined;

      if(this.isEmptyOrUndefined(externalUserData.pin)){
        this._confirmation.warn(
          undefined,
          this.l('::PinDoesNotExists'),
          this.messagePopUpOptions,
        );
      } else {
        pin = await HashingHelper.oneWayHashString(externalUserData.pin);
      }

      if (user === undefined) {
        await this._db.users.add(
          new User(userDto.id, externalUserData.externalId, userDto.userName, vendorId, pin),
        );
      } else if (user.vendorId === undefined || user.pin === undefined) {
        await this._db.users.update(user.id, { vendorId: vendorId, pin: pin });
      }
    }
  }

  async getUserVendorId(userId: string): Promise<number> {
    return (await this._db.users.get(userId)).vendorId;
  }

  async getUserExternalId(userId: string): Promise<string> {
    return (await this._db.users.get(userId)).externalId;
  }

  getById(id: string): Promise<User> {
    return this._db.users.get(id);
  }

  async doPinsMatch(pin: string, userId: string): Promise<boolean> {
    const currentUser = await this.getById(userId);
    return await HashingHelper.compare(pin, currentUser.pin);
  }

  async refreshUsersExternalData() {
    const users = await this._db.users.toArray();
    for (const user of users) {
      const externalUserData = await firstValueFrom(
        this.userService.getExternalUserDataByUsernameByUsername(user.username),
      );
      const vendorId = externalUserData.vendorId;
      const pin = await HashingHelper.oneWayHashString(externalUserData.pin);
      await this._db.users.update(user.id, { vendorId: vendorId, pin: pin });
    }
  }

  async getUserPin(userId: string): Promise<string> {
    return (await this._db.users.get(userId)).pin;
  }
}
