import { Injector } from '@angular/core';
import { ServiceBase } from '../../services/service-base';
import { SynchronizationEntity, SynchronizationState } from '../entities/synchronization-status';
import { LocalDbConsts } from '../local-db-consts';
import { SynchronizationStatusRepository } from '../repositories/synchronization-status-repository';
import { SynchronizationFrequencies } from './synchronization-frequencies';
import { HealthCheckService } from '../../services/HealthCheckService';
import { firstValueFrom } from 'rxjs';

export abstract class SynchronizationServiceBase extends ServiceBase {
  protected entityToSync: SynchronizationEntity;
  protected abstract synchronize(): Promise<void>;

  private readonly _statusRepository: SynchronizationStatusRepository;
  private _syncInterval: number;
  public get syncInterval(): number {
    return this._syncInterval;
  }
  private set syncInterval(value: number) {
    this._syncInterval = value;
  }
  private _retryInterval: number;

  private readonly _networkServiceCheck: HealthCheckService;

  constructor(injector: Injector) {
    super(injector);
    this._statusRepository = injector.get(SynchronizationStatusRepository);
    this._networkServiceCheck = injector.get(HealthCheckService);

    setTimeout(async () => await this.scheduleInitialSync());
  }

  public async manualSynchronize(){
    try {
      await this.synchronize();
      await this._statusRepository.updateStatus(this.entityToSync, SynchronizationState.Ok);
      return true;
    } catch (error) {
      await this._statusRepository.updateStatus(
        this.entityToSync,
        SynchronizationState.Error,
        error.message,
      );
      return false;
    }
  }

  protected async scheduleInitialSync(): Promise<void>{
    if (
      this.entityToSync !== undefined &&
      SynchronizationFrequencies.frequencies[this.entityToSync] !== undefined
    ) {
      this.configureFrequencies();
      const master = await this._statusRepository.getStatusByEntity(this.entityToSync);
      const lastSync = master.lastSynchronizationDate;
      const timeout = this.getNextSyncTime(lastSync, this._syncInterval);
      await this.sleep(timeout);
      this.synchronizeAndReschedule();
    }
  }

  protected configureFrequencies() {
    if (
      this.entityToSync !== undefined &&
      SynchronizationFrequencies.frequencies[this.entityToSync] !== undefined
    ) {
      this._syncInterval =
        SynchronizationFrequencies.frequencies[this.entityToSync].syncIntervalInMilliseconds();
      this._retryInterval =
        SynchronizationFrequencies.frequencies[this.entityToSync].retryIntervalInMilliseconds();
    }
  }

  protected getNextSyncTime(lastSync: Date | undefined, interval: number) {
    if (lastSync === undefined) {
      return 0;
    }

    const diff = new Date().getTime() - lastSync.getTime();
    if (diff > interval) {
      return 0;
    }
    return interval - diff;
  }

  protected async synchronizeAndReschedule() {
    await this.processSynchronization();
    await this.sleep(this._syncInterval);
    this.synchronizeAndReschedule();
  }

  private async processSynchronization() {
    let done = false;
    let attemptNumber = 0;

    while (!done && attemptNumber < LocalDbConsts.maxSyncRetries) {
      try {
        //TODO abp.log.debug(this.l('ExecutingLocalDbSync', this.getEntityName()));
        console.log(this.l('::ExecutingLocalDbSync', this.getEntityName()));

        const isOnline = await firstValueFrom(this._networkServiceCheck.heartBeat$);
        if (!isOnline) {
          throw new Error(this.l('::LocalDbSyncAbortedNoConnection'));
        }

        await this.synchronize();
        done = true;
        await this._statusRepository.updateStatus(this.entityToSync, SynchronizationState.Ok);
        //TODO abp.log.debug(this.l('LocalDbSyncCompleted', this.getEntityName()));
        console.log(this.l('::LocalDbSyncCompleted', this.getEntityName()));
      } catch (error) {
        //TODO abp.log.debug(this.l('ErrorExecutingLocalDbSync', this.getEntityName(), attemptNumber, error));
        console.log(
          this.l('::ErrorExecutingLocalDbSync', this.getEntityName(), attemptNumber, error),
        );
        attemptNumber++;
        const waitTime = this.getRetryWaitTime(attemptNumber);
        await this._statusRepository.updateStatus(
          this.entityToSync,
          SynchronizationState.Error,
          error,
          waitTime,
        );
        await this.sleep(waitTime);
      }
    }

    if (!done) {
      //TODO abp.log.debug(this.l('LocalDbSyncMaxRetriesExceeded') + this.entityToSync);
      console.log(this.l('::LocalDbSyncMaxRetriesExceeded', this.entityToSync));
    }
  }

  private getRetryWaitTime(retries: number): number {
    return this._retryInterval * 2 ** (retries - 1);
  }

  private getEntityName(): string {
    return this.l(SynchronizationEntity[this.entityToSync]);
  }
}
