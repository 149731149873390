import { Injectable, Injector } from '@angular/core';
import { fields } from '../../utils/app-utils';
import { HourActivityReport } from '../entities/hour-activity-report';
import { HourActivityReportProgressState } from '../entities/hour-activity-report-progress-state';
import { Process } from '../entities/process';
import { LocalRepositoryBase } from './local-repository-base';

@Injectable()
export class HourActivityReportRepository extends LocalRepositoryBase {
  constructor(_injector: Injector) {
    super(_injector);
  }

  async fetchAllOrderedByDate(): Promise<HourActivityReport[]> {
    const reports: HourActivityReport[] = await this._db.hourActivityReports
      .orderBy(fields<HourActivityReport>().startDate)
      .reverse()
      .toArray();
    for (const report of reports) {
      await this.assignProcessName(report);
    }
    return reports;
  }

  async assignProcessName(report: HourActivityReport): Promise<void> {
    const process: Process = await this._db.processes.get(report.process);
    report.processName = process.name;
  }

  getById(id: number): Promise<HourActivityReport> {
    return this._db.hourActivityReports.get(id);
  }

  update(hourActivityReport: HourActivityReport): Promise<any> {
    return this._db.hourActivityReports.update(hourActivityReport.id, hourActivityReport);
  }

  create(hourActivityReport: HourActivityReport): Promise<number> {
    return this._db.hourActivityReports.add(hourActivityReport);
  }

  async setIsFinishing(id: number): Promise<void> {
    let hourActivityReport = await this._db.hourActivityReports.get(id);
    hourActivityReport.progressState = HourActivityReportProgressState.Finishing;
    await this._db.hourActivityReports.update(hourActivityReport.id, hourActivityReport);
  }

  async setHourActivityReportRemoteId(id: number, remoteId: string): Promise<void> {
    const report = await this.getById(id);
    report.remoteId = remoteId;
    await this.update(report);
  }

  setStartDate(id: number, startDate: Date): Promise<number> {
    return this._db.hourActivityReports.update(id, { startDate });
  }
}
