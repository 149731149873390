import { Rest, RestService } from '@abp/ng.core';
import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, of } from 'rxjs';
import { AppPaths } from '../app-paths';

@Injectable()
export class HealthCheckServiceProxy {
  constructor(private readonly http: RestService) {}

  /**
   * Returns an HTTP 200 response if the application is available.
   * @return Success
   */
  checkHealthServer(): Observable<boolean> {
    return this.http
      .request<null, HttpResponse<any>>(
        {
          method: 'GET',
          url: AppPaths.NetworkConnectionCheckUrl,
        } as Rest.Request<any>,
        { observe: Rest.Observe.Response, skipHandleError: true },
      )
      .pipe(
        map(x => x.ok),
        catchError((err, caught) => of(false)),
      );
  }

  checkHealth(): Observable<boolean> {
    return navigator.onLine ? this.checkHealthServer() : of(false);
  }
}
