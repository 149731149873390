import { Injectable, Injector } from "@angular/core";
import { ContractsRepository } from "../repositories/contracts-repository";
import { SynchronizationServiceBase } from "./synchronization-service-base";
import { SynchronizationEntity } from "../entities/synchronization-status";

@Injectable()
export class ContractsSyncService extends SynchronizationServiceBase {
    constructor(
        injector: Injector,
        private readonly _contractsRepository: ContractsRepository) {
        super(injector);
    }

    protected entityToSync = SynchronizationEntity.Contracts;

    async synchronize(): Promise<void> {
        const remoteContracts = await this._contractsRepository.fetchAll();
        await this._contractsRepository.saveMany(remoteContracts);
    }
}