export class User {
  id: string;
  externalId: string;
  username: string;
  vendorId: number;
  pin: string;

  constructor(id: string, externalId: string, username: string, vendorId: number, pin: string) {
    this.id = id;
    this.externalId = externalId;
    this.username = username;
    this.vendorId = vendorId;
    this.pin = pin;
  }
}
