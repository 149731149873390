import {
  AbpApplicationConfigurationService,
  AbpApplicationLocalizationService,
  CoreModule,
} from '@abp/ng.core';
import { FeatureManagementModule } from '@abp/ng.feature-management';
import { AbpOAuthModule } from '@abp/ng.oauth';
import { SettingManagementConfigModule } from '@abp/ng.setting-management/config';
import { ThemeSharedModule } from '@abp/ng.theme.shared';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { CommercialUiConfigModule } from '@volo/abp.commercial.ng.ui/config';
import { AccountAdminConfigModule } from '@volo/abp.ng.account/admin/config';
import { AccountPublicConfigModule } from '@volo/abp.ng.account/public/config';
import { AuditLoggingConfigModule } from '@volo/abp.ng.audit-logging/config';
import { IdentityConfigModule } from '@volo/abp.ng.identity/config';
import { LanguageManagementConfigModule } from '@volo/abp.ng.language-management/config';
import { registerLocale } from '@volo/abp.ng.language-management/locale';
import { OpeniddictproConfigModule } from '@volo/abp.ng.openiddictpro/config';
import { SaasConfigModule } from '@volo/abp.ng.saas/config';
import { TextTemplateManagementConfigModule } from '@volo/abp.ng.text-template-management/config';
import { HttpErrorComponent, ThemeLeptonXModule } from '@volosoft/abp.ng.theme.lepton-x';
import { AccountLayoutModule } from '@volosoft/abp.ng.theme.lepton-x/account';
import { SideMenuLayoutModule } from '@volosoft/abp.ng.theme.lepton-x/layouts';
import { NgxSpinnerModule } from 'ngx-spinner';
import { HealthCheckServiceProxy } from 'src/app/shared/service-proxies/HealthCheckServiceProxy';
import { HealthCheckService } from 'src/app/shared/services/HealthCheckService';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { APP_ROUTE_PROVIDER } from './route.provider';
import { LogoutInterceptor } from './shared/interceptors/logout-interceptor';
import { LocalDbModule } from './shared/local-db/local-db.module';
import { CustomApplicationConfigurationService } from './shared/services/custom-application-configuration.service';
import { CustomApplicationLocalizationService } from './shared/services/custom-application-localization.service';
import { AppVersionService } from './shared/services/app-version-service';
import { AppPwaVersionService } from './shared/services/app-pwa-version-service';
import { ErrorHandlerService } from './shared/services/error-handler-service';
import { FaroInitializerService } from './shared/observability/faro-initializer-service';

const initializerPWAVersionChecker = (pwaService: AppPwaVersionService) => () => pwaService;
const faroInitializer = (faroInitializer: FaroInitializerService) => () => faroInitializer;

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreModule.forRoot({
      environment,
      registerLocaleFn: registerLocale(),
    }),
    AbpOAuthModule.forRoot(),
    ThemeSharedModule.forRoot({
      httpErrorConfig: {
        errorScreen: {
          component: HttpErrorComponent,
          forWhichErrors: [401, 403, 404, 500],
          hideCloseIcon: true,
        },
      },
    }),
    AccountAdminConfigModule.forRoot(),
    AccountPublicConfigModule.forRoot(),

    IdentityConfigModule.forRoot(),
    LanguageManagementConfigModule.forRoot(),
    SaasConfigModule.forRoot(),
    AuditLoggingConfigModule.forRoot(),
    OpeniddictproConfigModule.forRoot(),
    TextTemplateManagementConfigModule.forRoot(),
    SettingManagementConfigModule.forRoot(),

    CommercialUiConfigModule.forRoot(),
    FeatureManagementModule.forRoot(),
    ThemeLeptonXModule.forRoot(),
    SideMenuLayoutModule.forRoot(),
    AccountLayoutModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    LocalDbModule,
    NgxSpinnerModule,
  ],
  providers: [
    APP_ROUTE_PROVIDER,
    {
      provide: AbpApplicationConfigurationService,
      useClass: CustomApplicationConfigurationService,
    },
    {
      provide: AbpApplicationLocalizationService,
      useClass: CustomApplicationLocalizationService,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LogoutInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializerPWAVersionChecker,
      deps: [AppPwaVersionService],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: faroInitializer,
      deps: [FaroInitializerService],
      multi: true
    },
    {
      provide: ErrorHandler,
      useClass: ErrorHandlerService
    },
    HealthCheckService,
    HealthCheckServiceProxy,
    AppVersionService,
    AppPwaVersionService,
    FaroInitializerService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }

