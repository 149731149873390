export class HourActivityReportOperatorToAdd {
    id: number
    name: string
    identityNumber: string

    constructor(operatorId: number, name: string, identityNumber: string) {
        this.id = operatorId;
        this.name = name;
        this.identityNumber = identityNumber;
    }
}