import { Injectable, Injector } from '@angular/core';
import { SynchronizationService } from '@proxy/synchronization';
import { firstValueFrom } from 'rxjs';
import { Location } from '../entities/location';
import { LocalDbConsts } from '../local-db-consts';
import { LocalRepositoryBase } from './local-repository-base';

@Injectable()
export class LocationsRepository extends LocalRepositoryBase {
  constructor(
    injector: Injector,
    private readonly _synchronizationService: SynchronizationService,
  ) {
    super(injector);
  }

  async fetchAll(): Promise<Location[]> {
    const remoteLocations = await firstValueFrom(
      this._synchronizationService.getAllLocationsForSynchronization(this.remoteServicesConfig),
    );
    return remoteLocations.map(
      d => new Location(d.id, d.name, d.latitude, d.longitude, d.regionId),
    );
  }

  async saveMany(locations: Location[]): Promise<void> {
    this._db.transaction(
      LocalDbConsts.localDbReadWriteTransaction,
      this._db.locations,
      async () => {
        await this._db.locations.clear();
        return this._db.locations.bulkPut(locations);
      },
    );
  }

  getAll(): Promise<Location[]> {
    return this._db.locations.toArray();
  }

  getById(id: number): Promise<Location> {
    return this._db.locations.get(id);
  }
}
