import { RoutesService, eLayoutType } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';
import { AppPaths } from './shared/app-paths';
import { AppPermissions } from './shared/app-permissions';

export const APP_ROUTE_PROVIDER = [
  { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];

function configureRoutes(routes: RoutesService) {
  return () => {
    routes.add([
      {
        path: '/',
        name: '::Menu:Home',
        iconClass: 'fas fa-home',
        order: 1,
        layout: eLayoutType.application,
      },
      {
        path: AppPaths.CreationWizardFullPath,
        name: '::AddHourActivityReport',
        iconClass: 'fas fa-clock',
        layout: eLayoutType.application,
        requiredPolicy: AppPermissions.HourActivityReport,
      },
      {
        path: AppPaths.StatusMonitor,
        name: '::Synchronization',
        iconClass: 'fa fa-cloud',
        layout: eLayoutType.application,
        requiredPolicy: AppPermissions.HourActivityReport,
      },
      {
        path: AppPaths.HourActivityReportSynchronizationMonitor,
        name: '::HourActivityReportSync',
        iconClass: 'fa fa-cloud',
        layout: eLayoutType.application,
        requiredPolicy: AppPermissions.HourActivityReport,
      },
      {
        path: AppPaths.About,
        name: '::About',
        iconClass: 'fa fa-cog',
        layout: eLayoutType.application,
        requiredPolicy: AppPermissions.HourActivityReport,
      },
      {
        path: AppPaths.Help,
        name: '::Help',
        iconClass: 'fas fa-info-circle',
        layout: eLayoutType.application,
        requiredPolicy: AppPermissions.HourActivityReport,
      },
    ]);
  };
}
