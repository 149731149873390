export class HourActivityReportOperator {
    id: number;
    operatorId: number;
    mode: OperatorMode;
    name: string;
    hourActivityReportId: number;
    identityNumber: string;
    isConfirmed: boolean;
    latitude: number;
    longitude: number;
    endLatitude: number;
    endLongitude: number;
    confirmationMode: OperatorMode;
    qrCode: string;

    constructor(operatorId: number, mode: OperatorMode, name: string, hourActivityReportId: number, 
        identityNumber: string, qrCode: string) {
        this.operatorId = operatorId;
        this.mode = mode;
        this.name = name;
        this.hourActivityReportId = hourActivityReportId;
        this.identityNumber = identityNumber;
        this.isConfirmed = false;
        this.qrCode = qrCode
    }
}

export enum OperatorMode{
    QrCode = 0,
    Manual = 1,
}