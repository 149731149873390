import { Injectable, Injector } from '@angular/core';
import { SynchronizationEntity, SynchronizationState, SynchronizationStatus } from '../entities/synchronization-status';
import { LocalRepositoryBase } from './local-repository-base';

@Injectable()
export class SynchronizationStatusRepository extends LocalRepositoryBase {

    constructor(injector: Injector) {
        super(injector);
    }

    getStatusByEntity(entity: SynchronizationEntity): Promise<SynchronizationStatus> {
        return this._db.synchronizationStatus.get(entity);
    }

    async updateStatus(entity: SynchronizationEntity, state: SynchronizationState, error?: string | undefined, waitTime?: number | undefined) {
        const status = await this.getStatusByEntity(entity);
        status.lastSynchronizationAttemptDate = new Date();
        if (state === SynchronizationState.Ok) {
            status.lastSynchronizationDate = status.lastSynchronizationAttemptDate;
            status.nextSynchronization = new Date(status.lastSynchronizationDate.getTime() + status.synchronizationFrequencyInMilliseconds);
        } else if (state === SynchronizationState.Error) {
            status.nextSynchronization = new Date(status.lastSynchronizationAttemptDate.getTime() + waitTime);
        }
        status.state = state;
        status.lastSynchronizationErrorMessage = error;
        await this._db.synchronizationStatus.put(status);
    }

    async getSynchronizationStatus(state: SynchronizationState): Promise<SynchronizationStatus[]> {
        let synchronizationStatus = await this._db.synchronizationStatus
            .filter((syncStatus) => {
                if (state !== undefined) {
                    return syncStatus.state === state;
                }
                return true;
            })
            .filter((syncStatus) => syncStatus.isVisible)
            .toArray();

        return synchronizationStatus;
    }
}