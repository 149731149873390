import { Injectable, Injector } from '@angular/core';
import { NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { fields } from '../../utils/app-utils';
import { HourActivityReportActivity } from '../entities/hour-activity-report-activity';
import { LocalRepositoryBase } from './local-repository-base';

@Injectable()
export class ActivitiesRepository extends LocalRepositoryBase {
  constructor(injector: Injector) {
    super(injector);
  }

  async deleteHourActivityReportActivity(id: number): Promise<void> {
    await this._db.hourActivityReportActivities
      .where(fields<HourActivityReportActivity>().id)
      .equals(id)
      .delete();
  }

  getAllByHourActivityReportId(
    hourActivityReportId: number,
  ): Promise<HourActivityReportActivity[]> {
    return this._db.hourActivityReportActivities
      .where(fields<HourActivityReportActivity>().hourActivityReportId)
      .equals(hourActivityReportId)
      .toArray().then((activities: HourActivityReportActivity[]) => {
        activities.sort((a, b) => {
          const startTimeA = this.convertTimeStructToMilliseconds(a.startTime);
          const startTimeB = this.convertTimeStructToMilliseconds(b.startTime);
          const endTimeA = this.convertTimeStructToMilliseconds(a.endTime);
          const endTimeB = this.convertTimeStructToMilliseconds(b.endTime);
          if (startTimeA !== startTimeB) {
            return startTimeA - startTimeB;
          } else {
            return endTimeA - endTimeB;
          }
        });

        return activities;
      });
  }

  private convertTimeStructToMilliseconds(timeStruct: NgbTimeStruct): number {
    if (timeStruct) {
      const hoursInMilliseconds = timeStruct.hour * 60 * 60 * 1000;
      const minutesInMilliseconds = timeStruct.minute * 60 * 1000;
      const secondsInMilliseconds = timeStruct.second * 1000;

      return hoursInMilliseconds + minutesInMilliseconds + secondsInMilliseconds;
    }

    return 0;
  }

  async addHourActivityReportActivity(hourActivityReportActivity: HourActivityReportActivity): Promise<void> {
    await this._db.hourActivityReportActivities.add(hourActivityReportActivity);
  }

  async updateHourActivityReportActivity(hourActivityReportActivity: HourActivityReportActivity): Promise<any> {
    return await this._db.hourActivityReportActivities.update(hourActivityReportActivity.id, hourActivityReportActivity);
  }

  getById(id: number): Promise<HourActivityReportActivity> {
    return this._db.hourActivityReportActivities.get(id);
  }

  async getLastActivityByHourActivityReportId(hourActivityReportId: number): Promise<HourActivityReportActivity> {
    const activities = await this.getAllByHourActivityReportId(hourActivityReportId);
    return activities[activities.length - 1];
  }
}