import { Injectable, Injector } from '@angular/core';
import { SynchronizationService } from '@proxy/synchronization';
import { firstValueFrom } from 'rxjs';
import { ActivitySubgroup } from '../entities/activity-subgroup';
import { Process } from '../entities/process';
import { LocalDbConsts } from '../local-db-consts';
import { LocalRepositoryBase } from './local-repository-base';

@Injectable()
export class ActivitySubgroupsRepository extends LocalRepositoryBase {
  constructor(
    injector: Injector,
    private readonly _synchronizationService: SynchronizationService,
  ) {
    super(injector);
  }

  async fetchAll(): Promise<ActivitySubgroup[]> {
    const remoteActivitySubgroups = await firstValueFrom(
      this._synchronizationService.getAllActivitySubgroupsForSynchronization(
        this.remoteServicesConfig,
      ),
    );
    return remoteActivitySubgroups.map(
      d => new ActivitySubgroup(d.id, d.code, d.name, d.activityGroupId),
    );
  }

  async saveMany(activitySubgroups: ActivitySubgroup[]): Promise<void> {
    this._db.transaction(
      LocalDbConsts.localDbReadWriteTransaction,
      this._db.activitySubgroups,
      async () => {
        await this._db.activitySubgroups.clear();
        return this._db.activitySubgroups.bulkPut(activitySubgroups);
      },
    );
  }

    getById(id: number): Promise<ActivitySubgroup> {
        return this._db.activitySubgroups.get(id);
    }

    async getAll(): Promise<ActivitySubgroup[]> {
        return await this._db.activitySubgroups.toArray();
    }

    async getAllByProcess(process: Process): Promise<ActivitySubgroup[]> {
        let activitySubgroupIds = process.activitySubgroups;
        let activitySubgroups = await this.getAll();
        activitySubgroups = activitySubgroups.filter(x => activitySubgroupIds.includes(x.id));
        return activitySubgroups;
    }
}