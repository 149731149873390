import * as bcrypt from 'bcryptjs';

export class HashingHelper {
    static async oneWayHashString(stringToHash: string): Promise<string> {
        const salt = await bcrypt.genSalt(10);
        return await bcrypt.hash(stringToHash, salt);
    }

    static async compare(password: string, hashedString: string): Promise<boolean> {
        return await bcrypt.compare(password, hashedString);
    }
}
