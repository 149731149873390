import { AfterViewInit, Component } from '@angular/core';

@Component({
  selector: 'custom-breadcrumb',
  templateUrl: './custom-breadcrumb.component.html',
})
export class CustomBreadcrumb implements AfterViewInit {
  ngAfterViewInit(): void {
    document.getElementById('custom-breadcrumb').style.width =
      document.querySelector('.lpx-topbar').clientWidth + 'px';
  }
}
