import { ErrorHandler, Injectable } from '@angular/core';
import { MonitoringService } from './monitoring.service';
import { environment } from '../../../environments/environment';
import { AppConsts } from '../app-consts';

@Injectable({
    providedIn: 'root'
})
export class ErrorHandlerService implements ErrorHandler {
    private isEnabledAppInsights = false;

    constructor(private _monitoringService: MonitoringService) {
        this.isEnabledAppInsights = environment[AppConsts.ApplicationInsightsConfigurationName]?.length > 0;
    }

    handleError(error: Error): void {
        if (this.isEnabledAppInsights) {
            this._monitoringService.logException(error);
        } else {
            console.error(error);
        }
    }
}