export class Operator {
    id: number;
    qrCode: string;
    code: string;
    identityNumber: string;
    laborType: string;
    firstName: string;
    lastName: string;
    userName: string;
    vendorId: number;

    constructor(id: number, qrCode: string, code: string, identityNumber: string, laborType: string,
        firstName: string, lastName: string, userName: string, vendorId: number) {
        this.id = id;
        this.qrCode = qrCode;
        this.code = code;
        this.identityNumber = identityNumber;
        this.laborType = laborType;
        this.firstName = firstName;
        this.lastName = lastName;
        this.userName = userName;
        this.vendorId = vendorId;
    }
}