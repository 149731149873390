import { Injectable, Injector } from "@angular/core";
import { SynchronizationServiceBase } from "./synchronization-service-base";
import { SynchronizationEntity } from "../entities/synchronization-status";
import { LocationsRepository } from "../repositories/locations-repository";

@Injectable()
export class LocationsSyncService extends SynchronizationServiceBase {
    constructor(
        injector: Injector,
        private readonly _locationsRepository: LocationsRepository) {
        super(injector);
    }

    protected entityToSync = SynchronizationEntity.Locations;

    async synchronize(): Promise<void> {
        const remoteLocations = await this._locationsRepository.fetchAll();
        await this._locationsRepository.saveMany(remoteLocations);
    }
}