import { SynchronizationEntity } from '../entities/synchronization-status';

export class SynchronizationFrequencyConfiguration {
  syncInterval: string;
  retryInterval: string;

  constructor(syncInterval: string, retryInterval: string) {
    this.syncInterval = syncInterval;
    this.retryInterval = retryInterval;
  }

  syncIntervalInMilliseconds(): number {
    return this.convertToMilliseconds(this.syncInterval);
  }

  retryIntervalInMilliseconds(): number {
    return this.convertToMilliseconds(this.retryInterval);
  }

  private convertToMilliseconds(interval: string): number {
    let milliseconds = 0;
    if (interval?.length > 0) {
      interval = interval.trim().toUpperCase();

      let index = interval.indexOf('D');
      if (index >= 0) {
        milliseconds += 1000 * 60 * 60 * 24 * this.numberValue(interval, index - 1);
      }

      index = interval.indexOf('H');
      if (index >= 0) {
        milliseconds += 1000 * 60 * 60 * this.numberValue(interval, index - 1);
      }

      index = interval.indexOf('M');
      if (index >= 0) {
        milliseconds += 1000 * 60 * this.numberValue(interval, index - 1);
      }

      index = interval.indexOf('S');
      if (index >= 0) {
        milliseconds += 1000 * this.numberValue(interval, index - 1);
      }

      if (milliseconds <= 0 && Number.parseInt(interval) >= 0) {
        milliseconds += 1000 * Number.parseInt(interval);
      }
    }
    return milliseconds;
  }

  private numberValue(value: string, index: number): number | undefined {
    let n = '';
    for (let i = index; i >= 0; i--) {
      const element = value[i];
      if (Number.parseInt(element) >= 0) {
        n = element + n;
      } else {
        break;
      }
    }
    return n.length > 0 ? Number.parseInt(n) : 0;
  }
}

export class SynchronizationFrequencies {
  static readonly frequencies = {
    [SynchronizationEntity.Contracts]: new SynchronizationFrequencyConfiguration('30m', '2s'),
    [SynchronizationEntity.Processes]: new SynchronizationFrequencyConfiguration('30m', '2s'),
    [SynchronizationEntity.Locations]: new SynchronizationFrequencyConfiguration('30m', '2s'),
    [SynchronizationEntity.Operators]: new SynchronizationFrequencyConfiguration('30m', '2s'),
    [SynchronizationEntity.ActivitySubgroups]: new SynchronizationFrequencyConfiguration(
      '1h',
      '2s',
    ),
    [SynchronizationEntity.Users]: new SynchronizationFrequencyConfiguration('24h', '2s'),
    [SynchronizationEntity.HourActivityReportPending]: new SynchronizationFrequencyConfiguration('3m', '2s'),
  };
}
